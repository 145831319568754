<template lang="pug">
  v-dialog(:value="visible" width="500" @click:outside="close"
    @keydown.esc="close")
    v-card
      v-toolbar(color="success")
        v-btn(icon dark @click="close")
          v-icon mdi-close
        v-toolbar-title Edit
      v-card-text
        v-checkbox(v-if="checkbox" @change="copy" label='Copy changes to queue(s)' color="success")
        v-textarea(
          outlined
          name="text"
          :label="label"
          :value="text"
          @input="handleInput"
          style="caret-color: auto !important"
          color="success"
          ).mt-2
      v-card-actions
        v-spacer
        v-btn(color="green darken-1"
          text
          @click="$emit('accept')") Save
        v-btn(color="green darken-1"
          text
          @click="close") Cancel
</template>

<script>
export default {
  name: 'EditDialogue',

  model: {
    prop: 'text',
    event: 'blur',
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    checkbox: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Text',
    },
  },

  methods: {
    handleInput(value) {
      this.$emit('blur', value);
    },
    close() {
      this.$emit('close');
    },
    copy() {
      this.$emit('copy');
    },
  },
};
</script>

<style scoped>

</style>
<style>
textarea {
  background: none;
}
</style>
