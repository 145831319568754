<template lang="pug" class="v-application">
  v-dialog(:value="visible" @click:outside="close"
    @keydown.esc="close" scrollable max-width="500px")
    v-card
      v-toolbar(color="success")
        v-btn(icon dark @click="close")
          v-icon mdi-close
        v-toolbar-title Copy images from one library to the other library
      v-card-text.dialog_size
        v-select(
          v-model="library_id"
          label="Select the library"
          :items="myLibraries"
          item-text="name"
          item-value="ID"
          :value="library_id"
          dense
          :menu-props="{ maxHeight: '300' }"
        )
      v-card-actions
        v-spacer
        v-btn(color="green darken-1"
          text
          @click="copyToLibrary(); $emit('close')") Copy
        v-btn(color="green darken-1"
          text
          @click="close") Cancel
</template>

<script>
import axios from 'axios';

export default {
  name: 'CopyDialogue',

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      library_id: 0,
    };
  },
  computed: {
    myLibraries() {
      return this.$store.state.myLibraries;
    },
    getSelected() {
      return this.$store.getters.checkedImages;
    },
  },
  methods: {
    copyToLibrary() {
      axios.post(
        '/api/gallery/copy/images',
        { library_id: this.library_id, Ids: this.getSelected },
      );
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>

</style>
<style>
input[type="text"] {
  background: none !important;
}
.dialog_size{
  height: 300px;
}
</style>
