/*  eslint global-require: off */
/*  eslint no-unused-vars: off */
/*  eslint import/first: off */

require('./bootstrap');

import Vue from 'vue';
import vuePicturePreview from 'vue-picture-preview';
import VueCookie from 'vue-cookie';
import VueRouter from 'vue-router';
import VueAxios from 'vue-axios';
import JSONView from 'vue-json-component';
import moment from 'moment';

import vuetify from './plugins/vuetify';
import store from './store';
import routes from './routes';

const router = new VueRouter({ routes });
Vue.router = router;
Vue.config.devtools = true;

Vue.use(JSONView);
Vue.use(VueCookie);
Vue.use(VueRouter);
Vue.use(VueAxios, window.axios);

import Queue from './components/queue';
import Reconnect from './components/settings/reconnect';
import Dropbox from './components/settings/dropbox';
import Pause from './components/dashboard/Pause';
import Images from './components/library/Images';
import PublicLibrary from './components/library/publicLibrary';
import List from './components/library/List';
import Dashboard from './components/dashboard';
import Settings from './components/settings';
import Edit from './components/settings/Edit';
import Agency from './components/agency';
import NoImages from './components/admin/noImages';
import WrongSchedulers from './components/admin/WrongSchedulers';

Vue.component('Previewer', vuePicturePreview);

Vue.filter('Date', (value) => {
  if (!value) return '';
  return moment(String(value)).format('DD-MM-YYYY hh:mm');
});

const app = new Vue({
  el: '#root',
  components: {
    Queue,
    Reconnect,
    Dropbox,
    Pause,
    Images,
    PublicLibrary,
    List,
    Dashboard,
    Settings,
    Edit,
    Agency,
    NoImages,
    WrongSchedulers,
  },
  vuetify,
  router,
  store,
});

window.app = app;
