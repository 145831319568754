<template lang="pug">
  v-app
    v-container.white-board
      h3 Connect a Profile to Evergreen
      socials(:limit="limit" :ready="ready" @updateSettings="getData")
    v-container.white-board
      h3 Setup your added: Facebook, Twitter, LinkedIn, Instagram
      template(v-for="task in tasks")
        task(
          :task="task"
          :libraries="libraries"
          @take="take($event)"
        )
</template>

<script>
import axios from 'axios';
import _ from 'lodash';

import AlertMessage from '../common/AlertMessage';
import main from '../../mixins/main';
import Socials from '../settings/Socials';
import Task from './Task';

export default {
  name: 'Settings',
  components: {
    Socials,
    'alert-message': AlertMessage,
    Task,
  },
  mixins: [main],
  data() {
    return {
      alert_error: true,
      alert_success: true,
      limit: 1,
      tasks: [],
      libraries: [],
      plan: 1,
      ready: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      axios.get('/api/settings/main').then((response) => {
        this.tasks = _.get(response, 'data.schedulers', []);
        this.libraries = _.get(response, 'data.libraries', []);
        this.limit = _.get(response, 'data.limit', 0);
        this.plan = _.get(response, 'data.plan', 0);
        this.ready = true;
      });
    },
    take(id) {
      const index = this.tasks.map((item) => String(item.ID)).indexOf(String(id));
      this.tasks.splice(index, 1);
    },
  },
};
</script>

<style scoped>

</style>
