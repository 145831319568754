var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-alert",
        {
          attrs: {
            dismissible: "",
            outlined: "",
            type: "success",
            dense: "",
            transition: "scale-transition"
          },
          model: {
            value: _vm.success,
            callback: function($$v) {
              _vm.success = $$v
            },
            expression: "success"
          }
        },
        [_vm._v(_vm._s(_vm.message_success))]
      ),
      _c(
        "v-alert",
        {
          attrs: {
            dismissible: "",
            outlined: "",
            type: "error",
            dense: "",
            transition: "scale-transition"
          },
          model: {
            value: _vm.error,
            callback: function($$v) {
              _vm.error = $$v
            },
            expression: "error"
          }
        },
        [_vm._v(_vm._s(_vm.message_error))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }