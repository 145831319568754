var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("pause", {
        attrs: { visible: _vm.pauseDialog, shid: _vm.shid },
        on: {
          close: function($event) {
            _vm.pauseDialog = false
          },
          accept: function($event) {
            return _vm.pause($event)
          }
        }
      }),
      _c("un-pause", {
        attrs: { visible: _vm.unPauseDialogue, shid: _vm.shid },
        on: {
          close: function($event) {
            _vm.unPauseDialogue = false
          },
          accept: function($event) {
            return _vm.unPause()
          }
        }
      }),
      _c("remove-dialogue", {
        attrs: { visible: _vm.featured.remove },
        on: {
          close: function($event) {
            _vm.featured.remove = false
          },
          accept: _vm.trashSend
        }
      }),
      _c("edit-dialogue", {
        attrs: { visible: _vm.featured.edit },
        on: {
          close: function($event) {
            _vm.featured.edit = false
          },
          accept: _vm.editSend
        },
        model: {
          value: _vm.featured.text,
          callback: function($$v) {
            _vm.$set(_vm.featured, "text", $$v)
          },
          expression: "featured.text"
        }
      }),
      _c("own-dialog", {
        attrs: { visible: _vm.own.dialog },
        on: {
          submit: _vm.Own,
          close: function($event) {
            _vm.own.dialog = false
          }
        },
        model: {
          value: _vm.own.copyLib,
          callback: function($$v) {
            _vm.$set(_vm.own, "copyLib", $$v)
          },
          expression: "own.copyLib"
        }
      }),
      _c("permission-dialog", {
        attrs: { visible: _vm.permissionDialog },
        on: {
          close: function($event) {
            _vm.permissionDialog = false
          }
        }
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("h2", [
                _vm._v(
                  "Pages & Twitter Accounts you have prescheduled for posting."
                )
              ]),
              _c(
                "v-alert",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.tasks.length && _vm.ready,
                      expression: "!tasks.length && ready"
                    }
                  ],
                  attrs: {
                    border: "right",
                    "colored-border": "",
                    type: "error",
                    elevation: "2"
                  }
                },
                [
                  _c("b", [_vm._v("Ooops! ")]),
                  _vm._v("You haven’t configured any page/account yet. Please"),
                  _c("a", { attrs: { href: "/api/settings" } }, [
                    _vm._v(" click here ")
                  ]),
                  _vm._v("to configure")
                ]
              ),
              _vm._l(_vm.tasks, function(task, key) {
                return _c(
                  "v-container",
                  {
                    key: key,
                    staticClass: "white-board",
                    class: _vm.highlighted(task.paused_r)
                  },
                  [
                    _c(
                      "v-row",
                      { staticClass: "b-white" },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "pa-0",
                            class: _vm.highlighted(task.paused_r),
                            attrs: { cols: "2" }
                          },
                          [
                            _c(
                              "v-card",
                              { attrs: { elevation: "2" } },
                              [
                                _c(
                                  "v-alert",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: task.paused,
                                        expression: "task.paused"
                                      }
                                    ],
                                    staticClass: "mb-0",
                                    attrs: {
                                      type: "error",
                                      color: "warning",
                                      border: "right",
                                      text: "",
                                      dense: "",
                                      title:
                                        "This task is paused. " +
                                        task.paused +
                                        " failed attempts.Reason: " +
                                        task.info
                                    }
                                  },
                                  [_vm._v("Failed...")]
                                ),
                                _c(
                                  "v-card-text",
                                  {
                                    staticClass:
                                      "d-flex align-center justify-center social_image"
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: { "max-height": "100px" },
                                      attrs: {
                                        src: task.sn_image,
                                        title: task.page_name
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "v-card-title",
                                  { staticClass: "subtitle-2" },
                                  [
                                    _c("div", {
                                      staticClass: "fab",
                                      class: task.social_ico
                                    }),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "link",
                                        attrs: {
                                          href: task.sn_link,
                                          target: "_blank"
                                        }
                                      },
                                      [
                                        _vm._v("  " + _vm._s(task.page_name)),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: task.type === "group",
                                                expression:
                                                  "task.type === 'group'"
                                              }
                                            ]
                                          },
                                          [_vm._v("(group)")]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "pl-10",
                            class: _vm.highlighted(task.paused_r),
                            attrs: { cols: "8" }
                          },
                          [
                            _c(
                              "v-list",
                              {
                                class: _vm.highlighted(task.paused_r),
                                attrs: { "two-line": "" }
                              },
                              _vm._l(task.sources, function(source) {
                                return _c(
                                  "v-list-item",
                                  {
                                    staticStyle: {
                                      "min-height": "auto !important"
                                    },
                                    attrs: { multiple: "" }
                                  },
                                  [
                                    _c(
                                      "v-list-item-avatar",
                                      {
                                        staticClass: "ma-1 source",
                                        attrs: { color: "grey" }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "black" } },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.sourceIcon(source))
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      { staticClass: "pa-1" },
                                      [
                                        _c("v-list-item-title", {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.sourceType(source) +
                                                " : " +
                                                _vm.sourceName(source)
                                            )
                                          }
                                        }),
                                        _c("v-list-item-subtitle", {
                                          domProps: {
                                            textContent: _vm._s(
                                              "chance: " + source.percent + "%"
                                            )
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    source.wordpress
                                      ? _c(
                                          "v-list-item-action",
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  small: "",
                                                  color: "grey"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    _vm.copyKey(
                                                      _vm.sourceName(source),
                                                      key
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      color: "black"
                                                    }
                                                  },
                                                  [_vm._v("mdi-content-copy")]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    ref: "copy",
                                                    refInFor: true
                                                  },
                                                  [_vm._v("Copy key")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                            _c(
                              "v-container",
                              [
                                _c("v-chip", { attrs: { color: "grey" } }, [
                                  _vm._v("Time Interval: "),
                                  _c("b", [_vm._v(_vm._s(task.tname))])
                                ]),
                                _c(
                                  "v-chip",
                                  {
                                    staticClass: "ml-2",
                                    attrs: { color: "grey" }
                                  },
                                  [
                                    _vm._v("Recycle Posts: "),
                                    task.posted_count
                                      ? _c("b", [
                                          _vm._v(
                                            _vm._s(task.posted_count) +
                                              " (posts)"
                                          )
                                        ])
                                      : _c("b", [
                                          _vm._v(
                                            _vm._s(
                                              task.do_not_delete ? "Yes" : "No"
                                            )
                                          )
                                        ])
                                  ]
                                ),
                                task.paused_r !== null
                                  ? _c(
                                      "p",
                                      { staticClass: "mb-0" },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "warning" } },
                                          [_vm._v("mdi-clock")]
                                        ),
                                        _vm._v("Will auto unpause after: "),
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("Date")(task.paused_r.date)
                                            )
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c("v-divider", { staticClass: "ma-0" }),
                            _c(
                              "v-container",
                              { attrs: { justify: "space-around" } },
                              [
                                task.owned
                                  ? [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            large: "",
                                            color: "success"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.setOwn(
                                                task.page_id,
                                                task.ID
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-arrow-expand-all")
                                          ]),
                                          _vm._v("TAKE OWNERSHIP")
                                        ],
                                        1
                                      ),
                                      _c("span", { staticClass: "ml-5" }, [
                                        _vm._v(
                                          _vm._s(task.user.name) +
                                            " is owner (publisher)"
                                        )
                                      ])
                                    ]
                                  : [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-5",
                                          attrs: {
                                            large: "",
                                            color: "success",
                                            href:
                                              "/api/settings/edit?id=" + task.ID
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-square-edit-outline")
                                          ]),
                                          _vm._v("Edit")
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-5",
                                          attrs: { large: "", color: "error" },
                                          on: {
                                            click: function($event) {
                                              return _vm.remove(
                                                task.page_id,
                                                task.ID
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("v-icon", [_vm._v("mdi-delete")]),
                                          _vm._v("Delete")
                                        ],
                                        1
                                      ),
                                      task.paused_r == null
                                        ? [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "mr-5",
                                                attrs: {
                                                  large: "",
                                                  color: "grey"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.setPause(task.ID)
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: { color: "success" }
                                                  },
                                                  [_vm._v("mdi-pause")]
                                                ),
                                                _vm._v("Pause")
                                              ],
                                              1
                                            )
                                          ]
                                        : [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "mr-5",
                                                attrs: {
                                                  large: "",
                                                  color: "grey"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.setUnPause(
                                                      task.ID
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: { color: "success" }
                                                  },
                                                  [_vm._v("mdi-play")]
                                                ),
                                                _vm._v("Unpause")
                                              ],
                                              1
                                            )
                                          ],
                                      task.paused
                                        ? [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "mr-5",
                                                attrs: {
                                                  large: "",
                                                  color: "grey",
                                                  title:
                                                    "Click to reset the failed status"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.restore(task.ID)
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: { color: "primary" }
                                                  },
                                                  [_vm._v("mdi-reload")]
                                                ),
                                                _vm._v("Restore")
                                              ],
                                              1
                                            )
                                          ]
                                        : _vm._e()
                                    ]
                              ],
                              2
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "pa-0",
                            class: _vm.highlighted(task.paused_r),
                            attrs: { cols: "2" }
                          },
                          [
                            !task.owned && task.featured
                              ? _c(
                                  "v-card",
                                  {
                                    staticClass: "grey",
                                    attrs: { elevation: "2" }
                                  },
                                  [
                                    _c(
                                      "v-card-actions",
                                      { staticClass: "featured_panel" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold subtitle-2"
                                          },
                                          [_vm._v("NEXT POST:")]
                                        ),
                                        _c("v-spacer"),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "subtitle-2 link-green",
                                            attrs: {
                                              href: "/api/queue?id=" + task.ID
                                            }
                                          },
                                          [_vm._v("SEE ALL >")]
                                        )
                                      ],
                                      1
                                    ),
                                    _c("v-img", {
                                      staticClass: "px-1",
                                      attrs: {
                                        contain: "",
                                        src: task.featured.thumb
                                      }
                                    }),
                                    _c(
                                      "v-card-actions",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "subtitle-2",
                                            attrs: { small: "", text: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.edit(
                                                  task.featured.ID,
                                                  task.featured.text,
                                                  key
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { small: "" } },
                                              [
                                                _vm._v(
                                                  "mdi-square-edit-outline"
                                                )
                                              ]
                                            ),
                                            _vm._v("Edit")
                                          ],
                                          1
                                        ),
                                        _c("v-spacer"),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "subtitle-2",
                                            attrs: {
                                              small: "",
                                              text: "",
                                              color: "error"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.trash(
                                                  task.featured.ID,
                                                  key
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { small: "" } },
                                              [_vm._v("mdi-delete")]
                                            ),
                                            _vm._v("Delete")
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              !_vm.tasks.length
                ? [
                    _c("h2", [
                      _vm._v(
                        "All pages in which you can schedule & publish regularly"
                      )
                    ]),
                    _c(
                      "v-row",
                      [
                        _vm._l(_vm.pages, function(page, key) {
                          return _c(
                            "v-col",
                            { key: key, attrs: { cols: "2" } },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-card-text",
                                    { attrs: { title: page.name } },
                                    [
                                      _c("v-img", {
                                        staticClass: "all-tasks",
                                        attrs: {
                                          src:
                                            "https://graph.facebook.com/" +
                                            page.id +
                                            "/picture?type=square&height=73&width=73",
                                          "lazy-src":
                                            "/images/setting/ico-fb-min.png"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        _vm._l(_vm.groups, function(group, key) {
                          return _c(
                            "v-col",
                            { key: key, attrs: { cols: "2" } },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-card-text",
                                    {
                                      attrs: { title: group.name + "(group)" }
                                    },
                                    [
                                      _c("v-img", {
                                        staticClass: "all-tasks",
                                        attrs: {
                                          src: group.picture.url,
                                          "lazy-src":
                                            "/images/setting/ico-fb-m.png"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  ]
                : _vm._e(),
              _c(
                "v-alert",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.ready && !_vm.godMode,
                      expression: "ready && !godMode"
                    }
                  ],
                  staticClass: "mt-5",
                  attrs: {
                    border: "right",
                    "colored-border": "",
                    type: "info",
                    elevation: "2"
                  }
                },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "8" } }, [
                        _c("p", [
                          _vm._v(
                            "If you are having problems browsing your dropbox folders or your accountis connected to the wrong dropbox account, use this button"
                          )
                        ])
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "float-right",
                              attrs: { color: "primary", href: _vm.dropbox_url }
                            },
                            [
                              _c("v-icon", [_vm._v("mdi-dropbox")]),
                              _vm._v("Reconnect Dropbox")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "8" } }, [
                        _c("p", [
                          _vm._v(
                            "If you added new pages or your posts doesn't have permission for publish,use this button"
                          )
                        ])
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "float-right",
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.permissionDialog = true
                                }
                              }
                            },
                            [
                              _c("v-icon", [_vm._v("mdi-facebook")]),
                              _vm._v("Check Permissions")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }