var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.visible, "max-width": "400" },
      on: {
        "click:outside": _vm.close,
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.close($event)
        }
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { color: "success" } },
            [
              _c(
                "v-btn",
                { attrs: { icon: "", dark: "" }, on: { click: _vm.close } },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", [_vm._v("Check Permissions")])
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-radio-group",
                    {
                      model: {
                        value: _vm.force,
                        callback: function($$v) {
                          _vm.force = $$v
                        },
                        expression: "force"
                      }
                    },
                    [
                      _c("v-radio", {
                        attrs: { label: "Normal mode", color: "success" }
                      }),
                      _c("v-radio", {
                        attrs: {
                          label:
                            "Force mode. Edit all permission. Use it rarely if normal mode didn't help!",
                          value: "",
                          color: "success"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { color: "success", text: "", loading: _vm.loading },
                  on: { click: _vm.check }
                },
                [_vm._v("YES, Check Permissions")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }