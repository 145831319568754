var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-container",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.main,
              expression: "main"
            }
          ]
        },
        [
          _c(
            "div",
            { staticClass: "text-left mb-4 social" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-5",
                  attrs: { fab: "", title: "Facebook pages" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.setFb($event)
                    }
                  }
                },
                [
                  _c("v-img", {
                    attrs: { src: "/images/setting/icono-fb.png", width: "60" }
                  })
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mr-5",
                  attrs: { fab: "", title: "Facebook groups" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.setFbGroup($event)
                    }
                  }
                },
                [
                  _c("v-img", {
                    staticStyle: { "border-radius": "34px" },
                    attrs: { src: "/images/setting/icono-fbg.png", width: "68" }
                  })
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mr-5",
                  attrs: { fab: "", title: "Twitter account" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.setTw($event)
                    }
                  }
                },
                [
                  _c("v-img", {
                    attrs: {
                      src: "/images/setting/circle-twitter-60.png",
                      width: "65"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mr-5",
                  attrs: { fab: "", title: "LinkedIn account, companies" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.setLinkedIn($event)
                    }
                  }
                },
                [
                  _c("v-img", {
                    staticStyle: { "border-radius": "30px" },
                    attrs: { src: "/images/setting/lnkdin.png", width: "60" }
                  })
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { fab: "", title: "Instagram pages" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.setInstagram($event)
                    }
                  }
                },
                [
                  _c("v-img", {
                    staticStyle: { "border-radius": "30px" },
                    attrs: {
                      src: "/images/setting/instagram-60.jpeg",
                      width: "60"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("v-spacer"),
          _vm.ready && _vm.limit
            ? _c("p", [
                _vm._v("You can add"),
                _c("strong", [_vm._v(" " + _vm._s(_vm.limit) + " ")]),
                _vm._v(
                  "more pages or accounts. Please choose between Facebook, Twitter, LinkedIn, Instagram\nthen ADD!"
                )
              ])
            : _vm._e(),
          _vm.ready && !_vm.limit
            ? _c(
                "p",
                [
                  _c("v-icon", { attrs: { color: "warning" } }, [
                    _vm._v("mdi-alert")
                  ]),
                  _vm._v(
                    "You can't add more pages or twitter accounts, please  "
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        large: "",
                        color: "error",
                        href: "/api/packages"
                      }
                    },
                    [_vm._v("UPGRADE PLAN")]
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-btn",
            {
              attrs: {
                large: "",
                href: "/api/setting/reconnect",
                color: "primary"
              }
            },
            [
              _c("v-icon", [_vm._v("mdi-reload")]),
              _vm._v(" Reconnect Socials/Cloud")
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.main,
              expression: "!main"
            }
          ],
          attrs: { large: "", color: "primary" },
          nativeOn: {
            click: function($event) {
              return _vm.reset($event)
            }
          }
        },
        [_c("v-icon", [_vm._v("mdi-arrow-left-circle")]), _vm._v("Back")],
        1
      ),
      _c(
        "v-container",
        {
          directives: [
            { name: "show", rawName: "v-show", value: _vm.fb, expression: "fb" }
          ]
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pl-0", attrs: { cols: "6" } },
                [
                  _c("v-select", {
                    staticClass: "mr-4",
                    attrs: {
                      items: _vm.fb_pages,
                      label: "Please select facebook page",
                      "item-text": "page_name",
                      "item-value": "page_id",
                      dense: "",
                      "menu-props": { maxHeight: "450" }
                    },
                    model: {
                      value: _vm.selectedPage,
                      callback: function($$v) {
                        _vm.selectedPage = $$v
                      },
                      expression: "selectedPage"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: { large: "", color: "success" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.addPage(_vm.def.FB)
                        }
                      }
                    },
                    [_vm._v("Add now")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: { large: "", loading: _vm.refreshing },
                      nativeOn: {
                        click: function($event) {
                          return _vm.refreshFb($event)
                        }
                      }
                    },
                    [_vm._v("Refresh")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "p",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.fb_pages.length,
                  expression: "!fb_pages.length"
                }
              ]
            },
            [
              _c("v-icon", { attrs: { color: "info" } }, [
                _vm._v("mdi-information")
              ]),
              _vm._v("All of your connected pages been set up for publishing.")
            ],
            1
          ),
          _c(
            "p",
            [
              _c("v-icon", { attrs: { color: "info" } }, [
                _vm._v("mdi-information")
              ]),
              _vm._v(
                "Try checking your Facebook permissions to set up pages or groups not yet connected\nto Evergreen."
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.fb_group,
              expression: "fb_group"
            }
          ]
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pl-0", attrs: { cols: "6" } },
                [
                  _c("v-select", {
                    staticClass: "mr-4",
                    attrs: {
                      items: _vm.fb_groups,
                      label: "Please select facebook group",
                      "item-text": "page_name",
                      "item-value": "page_id",
                      dense: "",
                      "menu-props": { maxHeight: "450" }
                    },
                    model: {
                      value: _vm.selectedPage,
                      callback: function($$v) {
                        _vm.selectedPage = $$v
                      },
                      expression: "selectedPage"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: { large: "", color: "success" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.addPage(_vm.def.FB_GROUP)
                        }
                      }
                    },
                    [_vm._v("Add now")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: { large: "", loading: _vm.refreshing },
                      nativeOn: {
                        click: function($event) {
                          return _vm.refreshFbGroups($event)
                        }
                      }
                    },
                    [_vm._v("Refresh")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "p",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.fb_groups.length,
                  expression: "!fb_groups.length"
                }
              ]
            },
            [
              _c("v-icon", { attrs: { color: "info" } }, [
                _vm._v("mdi-information")
              ]),
              _vm._v("All of your connected groups been set up for publishing.")
            ],
            1
          ),
          _c(
            "p",
            [
              _c("v-icon", { attrs: { color: "info" } }, [
                _vm._v("mdi-information")
              ]),
              _vm._v(
                "Try checking your Facebook permissions to set up pages or groups not yet connected\nto Evergreen."
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        {
          directives: [
            { name: "show", rawName: "v-show", value: _vm.tw, expression: "tw" }
          ]
        },
        [
          _c(
            "v-row",
            { staticClass: "pl-0 mt-1" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-4",
                  attrs: { large: "", color: "info" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.addTw($event)
                    }
                  }
                },
                [
                  _c("v-icon", [_vm._v("mdi-twitter")]),
                  _vm._v("Add Twitter account")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.linkedIn,
              expression: "linkedIn"
            }
          ]
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pl-0", attrs: { cols: "3" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: { large: "", color: "info" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.addLinkedIn($event)
                        }
                      }
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-linkedin")]),
                      _vm._v("Add LinkedIn account")
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-select", {
                    staticClass: "mr-4",
                    attrs: {
                      items: _vm.linkedIn_companies,
                      label: "Please select LinkedIn company",
                      "item-text": "page_name",
                      "item-value": "page_id",
                      dense: "",
                      "menu-props": { maxHeight: "450" }
                    },
                    model: {
                      value: _vm.selectedPage,
                      callback: function($$v) {
                        _vm.selectedPage = $$v
                      },
                      expression: "selectedPage"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: { large: "", color: "success" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.addPage(_vm.def.LINKEDIN)
                        }
                      }
                    },
                    [_vm._v("Add now")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { large: "", loading: _vm.refreshing },
                      nativeOn: {
                        click: function($event) {
                          return _vm.refreshLinkedIn($event)
                        }
                      }
                    },
                    [_vm._v("Refresh")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "p",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.linkedIn_companies.length,
                  expression: "!linkedIn_companies.length"
                }
              ]
            },
            [
              _c("v-icon", { attrs: { color: "info" } }, [
                _vm._v("mdi-information")
              ]),
              _vm._v(
                "All of your connected companies been set up for publishing."
              )
            ],
            1
          ),
          _c(
            "p",
            [
              _c("v-icon", { attrs: { color: "info" } }, [
                _vm._v("mdi-information")
              ]),
              _vm._v("If company doesn't arrive in list click REFRESH")
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.instagram,
              expression: "instagram"
            }
          ]
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-select", {
                    staticClass: "mr-4",
                    attrs: {
                      items: _vm.instagram_pages,
                      label: "Please select Instagram page",
                      "item-text": "page_name",
                      "item-value": "page_id",
                      dense: "",
                      "menu-props": { maxHeight: "450" }
                    },
                    model: {
                      value: _vm.selectedPage,
                      callback: function($$v) {
                        _vm.selectedPage = $$v
                      },
                      expression: "selectedPage"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: { large: "", color: "success" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.addPage(_vm.def.INSTAGRAM)
                        }
                      }
                    },
                    [_vm._v("Add now")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { large: "", loading: _vm.refreshing },
                      nativeOn: {
                        click: function($event) {
                          return _vm.refreshInstagram($event)
                        }
                      }
                    },
                    [_vm._v("Refresh")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "p",
            {
              on: {
                click: function($event) {
                  _vm.instagramDialog = true
                }
              }
            },
            [
              _c("v-icon", { attrs: { color: "info" } }, [
                _vm._v("mdi-information")
              ]),
              _vm._v("Having trouble?"),
              _c("a", [_vm._v("  Connecting to Instagram instructions")])
            ],
            1
          ),
          _c(
            "p",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.instagram_pages.length,
                  expression: "!instagram_pages.length"
                }
              ]
            },
            [
              _c("v-icon", { attrs: { color: "info" } }, [
                _vm._v("mdi-information")
              ]),
              _vm._v("All of your connected pages been set up for publishing.")
            ],
            1
          ),
          _c(
            "p",
            [
              _c("v-icon", { attrs: { color: "info" } }, [
                _vm._v("mdi-information")
              ]),
              _vm._v(
                "Try checking your Facebook permissions to set up Instagram pages\nnot yet connected to Evergreen."
              )
            ],
            1
          )
        ],
        1
      ),
      _c("alert-message", { ref: "alertComponent" }),
      _c("instagram-dialogue", {
        attrs: { visible: _vm.instagramDialog },
        on: {
          close: function($event) {
            _vm.instagramDialog = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }