<template lang="pug">
  v-container
    nav-bar(:active="0")
    h3 Wrong active schedulers({{ schedulers.length }})
    v-progress-linear(
      v-show="loading"
      color="warning"
      indeterminate
      rounded
      height="6")
    v-simple-table
      template(v-slot:default)
        thead
          tr
            th.text-left Scheduler Id
            th Page Name
            th Owned
            th Social
            th Count Error
            th User Id
            th User
            th Info Error
            th Images
            th Token status
            th Last pushed
        tbody
          tr(v-for="(scheduler, key) in schedulers")
            td
              a(:href="`/api/queue?id=${scheduler.ID}`" target='_blank') {{ scheduler.ID }}
            td {{ scheduler.page_name }}
            td {{ scheduler.owned ? 'Yes' : 'No' }}
            td {{ scheduler.social_name }}
            td
              a(
                :href="`/admin/logs/index/${scheduler.ID}/1`"
                target='_blank'
              ) {{ scheduler.paused }}
            td
              a(:href="`/admin/user/makeLogin/${scheduler.UID}`") {{ scheduler.UID }}
            td {{ scheduler.user.name }}
            td {{ scheduler.info }}
            td {{ scheduler.featured_count }}
            td {{ scheduler.status }}
            td {{ scheduler.last_pushed ? scheduler.last_pushed.date : '' }}

</template>

<script>

import _ from 'lodash';
import axios from 'axios';
import NavBar from './NavBar';

export default {
  name: 'WrongSchedulers',
  components: {
    NavBar,
  },
  data() {
    return {
      loading: false,
      schedulers: {},
    };
  },
  created() {
    this.main();
  },
  methods: {
    main() {
      this.loading = true;
      axios.get('/api/wrongSchedulers').then((response) => {
        this.schedulers = _.get(response, 'data.schedulers', {});
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>

</style>
