var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("nav-bar", { attrs: { active: 0 } }),
      _c("h3", [
        _vm._v("Wrong active schedulers(" + _vm._s(_vm.schedulers.length) + ")")
      ]),
      _c("v-progress-linear", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        attrs: { color: "warning", indeterminate: "", rounded: "", height: "6" }
      }),
      _c("v-simple-table", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticClass: "text-left" }, [
                      _vm._v("Scheduler Id")
                    ]),
                    _c("th", [_vm._v("Page Name")]),
                    _c("th", [_vm._v("Owned")]),
                    _c("th", [_vm._v("Social")]),
                    _c("th", [_vm._v("Count Error")]),
                    _c("th", [_vm._v("User Id")]),
                    _c("th", [_vm._v("User")]),
                    _c("th", [_vm._v("Info Error")]),
                    _c("th", [_vm._v("Images")]),
                    _c("th", [_vm._v("Token status")]),
                    _c("th", [_vm._v("Last pushed")])
                  ])
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.schedulers, function(scheduler, key) {
                    return _c("tr", [
                      _c("td", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "/api/queue?id=" + scheduler.ID,
                              target: "_blank"
                            }
                          },
                          [_vm._v(_vm._s(scheduler.ID))]
                        )
                      ]),
                      _c("td", [_vm._v(_vm._s(scheduler.page_name))]),
                      _c("td", [
                        _vm._v(_vm._s(scheduler.owned ? "Yes" : "No"))
                      ]),
                      _c("td", [_vm._v(_vm._s(scheduler.social_name))]),
                      _c("td", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "/admin/logs/index/" + scheduler.ID + "/1",
                              target: "_blank"
                            }
                          },
                          [_vm._v(_vm._s(scheduler.paused))]
                        )
                      ]),
                      _c("td", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "/admin/user/makeLogin/" + scheduler.UID
                            }
                          },
                          [_vm._v(_vm._s(scheduler.UID))]
                        )
                      ]),
                      _c("td", [_vm._v(_vm._s(scheduler.user.name))]),
                      _c("td", [_vm._v(_vm._s(scheduler.info))]),
                      _c("td", [_vm._v(_vm._s(scheduler.featured_count))]),
                      _c("td", [_vm._v(_vm._s(scheduler.status))]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            scheduler.last_pushed
                              ? scheduler.last_pushed.date
                              : ""
                          )
                        )
                      ])
                    ])
                  }),
                  0
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }