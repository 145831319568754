<template lang="pug">
  v-app
</template>

<script>
import store from '../store';
import Queue from './queue';

export default {

  name: 'App',

  store,

  components: {
    Queue,
  },

  data() {
    return {};
  },

  computed: {},

  created() {},

  mounted() {},

  methods: {},
};
</script>

<style>
  v-app {
    min-height: inherit;
  }
</style>
