<template lang="pug">
  v-dialog(:value="visible" width="100%" @click:outside="closeDialog()"
    @keydown.esc="closeDialog")
    v-card
      v-toolbar(color="success")
        v-btn(icon dark @click="closeDialog()")
          v-icon mdi-close
        v-toolbar-title Uploads
      v-card-text
        vue-dropzone(id="drop1" :options="dropOptions" ref="myVueDropzone")
      v-card-actions
        v-spacer
        v-btn(color="success" @click="closeDialog()").btn-large Save
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
  name: 'Uploads',
  components: {
    vueDropzone,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    libraryId: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      dropOptions: {
        url: '/api/image/uploads',
        method: 'post',
        filesizeBase: 1024,
        acceptedFiles: 'image/jpg,image/jpeg,image/png,image/gif,video/mp4',
        params: {
          gallery_id: '0',
        },
        dictDefaultMessage: 'Drop files here to upload. Only PNG, JPG, GIF '
          + 'or video mp4. Up to 10 MB',
      },
    };
  },
  watch: {
    libraryId() {
      this.dropOptions.params.gallery_id = this.libraryId;
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close');
      this.$refs.myVueDropzone.removeAllFiles();
    },
  },
};
</script>

<style scoped>

</style>
