var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-md-12" },
    [
      _c("remove-all-dialogue", {
        attrs: { visible: _vm.removeAllDialogue },
        on: {
          close: function($event) {
            _vm.removeAllDialogue = false
          },
          accept: _vm.deleteSelected
        }
      }),
      _c("uploads", {
        attrs: { visible: _vm.uploadsDialog, libraryId: String(_vm.galleryId) },
        on: { close: _vm.uploadsClosed }
      }),
      _c("uploads-public", {
        attrs: {
          visible: _vm.uploadsPublicDialog,
          galleries: _vm.publicGalleries,
          libraryId: String(_vm.galleryId)
        },
        on: { close: _vm.uploadsPublicClosed }
      }),
      _c("copy-dialog", {
        attrs: { visible: _vm.copyDialogue },
        on: {
          close: function($event) {
            _vm.copyDialogue = false
          }
        }
      }),
      _c(
        "v-row",
        [
          _c("ul", [
            _c("li", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.all,
                    expression: "all"
                  }
                ],
                staticClass: "im_set",
                attrs: { id: "all", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.all)
                    ? _vm._i(_vm.all, null) > -1
                    : _vm.all
                },
                on: {
                  click: _vm.setAll,
                  change: function($event) {
                    var $$a = _vm.all,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.all = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.all = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.all = $$c
                    }
                  }
                }
              }),
              _c("label", { attrs: { for: "all" } }, [_vm._v(" All")])
            ]),
            _c("li", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.allPublished,
                    expression: "allPublished"
                  }
                ],
                staticClass: "im_set",
                attrs: { id: "all_published", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.allPublished)
                    ? _vm._i(_vm.allPublished, null) > -1
                    : _vm.allPublished
                },
                on: {
                  click: _vm.setPublished,
                  change: function($event) {
                    var $$a = _vm.allPublished,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.allPublished = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.allPublished = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.allPublished = $$c
                    }
                  }
                }
              }),
              _c("label", { attrs: { for: "all_published" } }, [
                _vm._v(" All published")
              ])
            ])
          ]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "images_menu",
              attrs: { color: "error", outlined: "" },
              on: {
                click: function($event) {
                  _vm.removeAllDialogue = true
                }
              }
            },
            [
              _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-delete")]),
              _vm._v("Delete")
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "images_menu",
              attrs: { color: "success", outlined: "" },
              on: { click: _vm.unmarkImages }
            },
            [
              _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-reply")]),
              _vm._v("Unmark")
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "images_menu",
              attrs: { color: "success", outlined: "" },
              on: {
                click: function($event) {
                  _vm.copyDialogue = true
                }
              }
            },
            [
              _c("v-icon", { attrs: { left: "" } }, [
                _vm._v("mdi-content-copy")
              ]),
              _vm._v("Copy")
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "images_menu",
              attrs: { color: "success" },
              on: {
                click: function($event) {
                  _vm.uploadsPublicDialog = true
                }
              }
            },
            [
              _c("v-icon", { attrs: { left: "" } }, [
                _vm._v("mdi-cloud-download")
              ]),
              _vm._v("Get Images from Evergreen")
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "images_menu",
              attrs: {
                id: "uploads",
                color: "success",
                "data-toggle": "modal"
              },
              on: {
                click: function($event) {
                  _vm.uploadsDialog = true
                }
              }
            },
            [
              _c("v-icon", { attrs: { left: "" } }, [
                _vm._v("mdi-cloud-upload")
              ]),
              _vm._v("Upload")
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-progress-linear", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            attrs: {
              color: "warning",
              indeterminate: "",
              rounded: "",
              height: "6"
            }
          }),
          _vm._l(_vm.images, function(image) {
            return _c("image-component", {
              key: image.ID,
              attrs: { image: image, twitter: _vm.twitter },
              on: { updateGallery: _vm.updateGallery }
            })
          }),
          _c(
            "v-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.images.length,
                  expression: "!images.length"
                }
              ],
              staticClass: "mt-5",
              attrs: { align: "center", justify: "space-around" }
            },
            [
              _c("p", { staticClass: "white-board" }, [
                _vm._v("This library is currently empty."),
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        _vm.uploadsDialog = true
                      }
                    }
                  },
                  [_vm._v(" Add some images.")]
                )
              ])
            ]
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }