<template lang="pug" class="v-application">
  v-dialog(:value="visible" @click:outside="close"
    @keydown.esc="close" scrollable max-width="500px")
    v-card
      v-toolbar(color="success")
        v-btn(icon dark @click="close")
          v-icon mdi-close
        v-toolbar-title This adds a copy of this post to the top
        br
        | of your queue
      v-card-text.dialog_size
        v-select(
          label="Select the scheduler"
          :items="schedulers"
          item-text="page_name"
          item-value="ID"
          :value="selected"
          @change="onChange($event)"
          dense
          :menu-props="{ maxHeight: '300' }"
        )
      v-card-actions
        v-spacer
        v-btn(color="green darken-1"
          text
          @click="toTop(); $emit('close')") Add
        v-btn(color="green darken-1"
          text
          @click="close") Cancel
</template>

<script>
import axios from 'axios';

export default {
  name: 'TopDialogue',

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      selected: 0,
      Id: this.id,
    };
  },
  computed: {
    schedulers() {
      return this.$store.state.schedulers;
    },
  },
  methods: {
    handleInput(value) {
      this.$emit('blur', value);
    },
    onChange(id) {
      this.scheduler_id = id;
    },
    toTop() {
      axios.post(
        '/api/image/to-queue',
        { ID: this.Id, scheduler_id: this.scheduler_id },
      );
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>

</style>
<style>
input[type="text"] {
  background: none !important;
}
.dialog_size{
  height: 300px;
}
</style>
