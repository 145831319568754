<template lang="pug">
  v-overlay
    v-progress-circular(indeterminate size="64")
</template>

<script>
export default {
  name: 'Loader',
};
</script>

<style scoped>

</style>
