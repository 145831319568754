<template lang="pug">
  v-app
    pause(
      :visible="pauseDialog"
      :shid="shid"
      @close="pauseDialog=false"
      @accept="pause($event)"
      )
    un-pause(
      :visible="unPauseDialogue"
      :shid="shid"
      @close="unPauseDialogue=false"
      @accept="unPause()"
    )
    remove-dialogue(
      :visible="featured.remove"
      @close="featured.remove = false"
      @accept="trashSend"
    )
    edit-dialogue(
      :visible="featured.edit"
      v-model="featured.text"
      @close="featured.edit = false"
      @accept="editSend"
    )
    own-dialog(
      :visible="own.dialog"
      v-model="own.copyLib"
      @submit="Own"
      @close="own.dialog = false"
    )
    permission-dialog(:visible="permissionDialog" @close="permissionDialog = false")
    v-row
      v-col(cols="12")
        h2 Pages & Twitter Accounts you have prescheduled for posting.
        v-alert(v-show="!tasks.length && ready"
          border="right"
          colored-border
          type="error"
          elevation="2"
        )
          b Ooops!&nbsp;
          | You haven’t configured any page/account yet. Please
          a(href='/api/settings') &nbsp;click here&nbsp;
          | to configure
        v-container(
          v-for="(task, key) in tasks"
          :key="key"
          :class="highlighted(task.paused_r)"
          ).white-board
          v-row.b-white
            v-col(cols="2" :class="highlighted(task.paused_r)").pa-0
              v-card(elevation="2")
                v-alert(v-show="task.paused"
                  type="error"
                  color="warning"
                  border="right"
                  text
                  dense
                  :title="`This task is paused. ${task.paused} failed attempts."+
                    "Reason: ${task.info}`"
                ).mb-0 Failed...
                v-card-text.d-flex.align-center.justify-center.social_image
                  img(:src="task.sn_image" :title="task.page_name" style="max-height: 100px;")
                v-card-title.subtitle-2
                  div(:class="task.social_ico").fab
                  a(:href="task.sn_link" target='_blank').link &nbsp; {{ task.page_name }}
                    span(v-show="task.type === 'group'") (group)
            v-col(cols="8" :class="highlighted(task.paused_r)").pl-10
              v-list(two-line :class="highlighted(task.paused_r)")
                v-list-item(
                  multiple
                  v-for="source in task.sources"
                  style="min-height: auto !important;"
                  )
                  v-list-item-avatar(color="grey").ma-1.source
                    v-icon(color="black") {{sourceIcon(source)}}
                  v-list-item-content.pa-1
                      v-list-item-title(v-text="`${sourceType(source)} : ${sourceName(source)}`")
                      v-list-item-subtitle(v-text="`chance: ${source.percent}%`")
                  v-list-item-action(v-if="source.wordpress")
                    v-btn(@click="copyKey(sourceName(source), key)" small color="grey")
                      v-icon(small color="black") mdi-content-copy
                      span(ref="copy") Copy key
              v-container
                v-chip(color="grey") Time Interval:&nbsp;
                  b {{ task.tname }}
                v-chip(color="grey").ml-2 Recycle Posts:&nbsp;
                  b(v-if="task.posted_count") {{ task.posted_count }} (posts)
                  b(v-else) {{ task.do_not_delete? 'Yes' : 'No' }}
                p(v-if="task.paused_r !== null").mb-0
                  v-icon(color="warning") mdi-clock
                  | Will auto unpause after:&nbsp;
                  b {{ task.paused_r.date | Date }}
              v-divider.ma-0
              v-container(justify="space-around")
                template(v-if="task.owned")
                  v-btn(
                    large
                    color="success"
                    @click="setOwn(task.page_id, task.ID)"
                    )
                    v-icon() mdi-arrow-expand-all
                    | TAKE OWNERSHIP
                  span.ml-5 {{ task.user.name }} is owner (publisher)
                template(v-else)
                  v-btn(large color="success" :href="`/api/settings/edit?id=${task.ID}`").mr-5
                    v-icon mdi-square-edit-outline
                    | Edit
                  v-btn(large color="error" @click="remove(task.page_id, task.ID)").mr-5
                    v-icon mdi-delete
                    | Delete
                  template(v-if="task.paused_r == null")
                    v-btn(large color="grey" @click="setPause(task.ID)").mr-5
                      v-icon(color="success") mdi-pause
                      | Pause
                  template(v-else)
                    v-btn(large color="grey" @click="setUnPause(task.ID)").mr-5
                      v-icon(color="success") mdi-play
                      | Unpause
                  template(v-if="task.paused")
                    v-btn(
                      large
                      color="grey"
                      @click="restore(task.ID)"
                      title="Click to reset the failed status"
                      ).mr-5
                        v-icon(color="primary") mdi-reload
                        | Restore

            v-col(cols="2" :class="highlighted(task.paused_r)").pa-0
              v-card(elevation="2" v-if="!task.owned && task.featured").grey
                v-card-actions.featured_panel
                  .font-weight-bold.subtitle-2 NEXT POST:
                  v-spacer
                  a(:href="`/api/queue?id=${task.ID}`").subtitle-2.link-green SEE ALL >
                v-img(contain :src="task.featured.thumb").px-1
                v-card-actions
                  v-btn(
                    small
                    text
                    @click="edit(task.featured.ID,task.featured.text, key)"
                    ).subtitle-2
                    v-icon(small) mdi-square-edit-outline
                    | Edit
                  v-spacer
                  v-btn(small text color="error" @click="trash(task.featured.ID, key)").subtitle-2
                    v-icon(small) mdi-delete
                    | Delete
        template(v-if="!tasks.length")
          h2 All pages in which you can schedule & publish regularly
          v-row
            v-col(cols="2" v-for="(page, key) in pages" :key="key")
              v-card
                v-card-text(:title="page.name")
                  v-img(
                    :src="`https://graph.facebook.com/${page.id}/picture?type=square&height=73&width=73`"
                    lazy-src='/images/setting/ico-fb-min.png'
                  ).all-tasks
            v-col(cols="2" v-for="(group, key) in groups" :key="key")
              v-card
                v-card-text(:title="`${group.name}(group)`")
                  v-img(:src="group.picture.url" lazy-src='/images/setting/ico-fb-m.png').all-tasks
        v-alert(
          v-show="ready && !godMode"
          border="right"
          colored-border
          type="info"
          elevation="2"
          ).mt-5
          v-row
            v-col(cols="8")
              p If you are having problems browsing your dropbox folders or your account
                | is connected to the wrong dropbox account, use this button
            v-col(cols="4")
              v-btn(color="primary" :href="dropbox_url").float-right
                v-icon mdi-dropbox
                | Reconnect Dropbox
          v-row
            v-col(cols="8")
              p If you added new pages or your posts doesn't have permission for publish,
                | use this button
            v-col(cols="4")
              v-btn(@click="permissionDialog=true" color="primary").float-right
                v-icon mdi-facebook
                | Check Permissions

</template>

<script>

import _ from 'lodash';
import axios from 'axios';
import main from '../../mixins/main';
import UnPause from './UnPause';
import Pause from './Pause';
import EditDialog from '../queue/EditDialogue';
import RemoveDialogue from '../queue/RemoveDialogue';
import OwnDialog from './Own';
import Permissions from './Permissions';

export default {
  name: 'Dashboard',
  components: {
    UnPause,
    Pause,
    'edit-dialogue': EditDialog,
    'remove-dialogue': RemoveDialogue,
    'own-dialog': OwnDialog,
    'permission-dialog': Permissions,
  },
  mixins: [main],
  data() {
    return {
      pages: {},
      groups: {},
      tasks: {},
      dropbox_url: '',
      unPauseDialogue: false,
      pauseDialog: false,
      permissionDialog: false,
      shid: 0,
      key: 0,
      ready: false,
      featured: {
        ID: 0,
        text: '',
        edit: false,
        remove: false,
      },
      own: {
        dialog: false,
        copyLib: false,
        page_id: 0,
        task_id: 0,
      },
      godMode: false,
    };
  },
  computed: {
    //
  },
  watch: {
    //
  },
  created() {
    this.getData();
  },
  mounted() {
    //
  },
  methods: {
    getData() {
      axios.get('/api/dashboard/main').then((response) => {
        this.pages = _.get(response, 'data.pages', {});
        this.groups = _.get(response, 'data.groups', {});
        this.tasks = _.get(response, 'data.tasks', {});
        this.dropbox_url = _.get(response, 'data.dropbox_url', '');
        this.godMode = _.get(response, 'data.godMode', false);
        this.ready = true;
      });
    },
    remove(pageId, id) {
      const index = this.getIndex(id);
      this.tasks.splice(index, 1);

      axios.delete(`/api/setting/pause/${pageId}`);
    },
    Own() {
      const index = this.getIndex(this.own.task_id);
      this.tasks[index].owned = false;
      axios.put(`/api/setting/own/${this.own.page_id}/${Number(this.own.copyLib)}`).then((response) => {
        const newSources = _.get(response, 'data.sources', []);
        if (newSources.length) {
          _.each(newSources, (item) => {
            this.tasks[index].sources.push(item);
          });
        }
      });
      this.own.dialog = false;
    },
    setOwn(pageId, id) {
      this.own.page_id = pageId;
      this.own.task_id = id;
      this.own.dialog = true;
    },
    setPause(id) {
      this.shid = id;
      this.pauseDialog = true;
    },
    setUnPause(id) {
      this.shid = id;
      this.unPauseDialogue = true;
    },
    unPause() {
      this.unPauseDialogue = false;
      this.pauseValue(null);
    },
    pause(date) {
      this.pauseDialog = false;
      this.pauseValue(true, date);
    },
    pauseValue(paused, date = '') {
      const index = this.getIndex(this.shid);
      if (date) {
        this.tasks[index].paused_r = { date };
      } else {
        this.tasks[index].paused_r = paused;
      }
    },
    restore(id) {
      const index = this.getIndex(id);
      this.tasks[index].paused = false;
      axios.patch(`/api/setting/restore/${id}`);
    },
    getIndex(id) {
      return this.tasks.map((item) => String(item.ID)).indexOf(String(id));
    },
    highlighted(paused) {
      return paused !== null ? 'white-board-paused' : '';
    },
    edit(id, text, key) {
      this.key = key;
      this.featured.ID = id;
      this.featured.text = text;
      this.featured.edit = true;
    },
    trash(id, key) {
      this.key = key;
      this.featured.ID = id;
      this.featured.remove = true;
    },
    editSend() {
      this.featured.edit = false;
      const data = { ID: this.featured.ID, text: this.featured.text };
      axios.put('/api/queue/item', data).then(() => {
        this.tasks[this.key].featured.text = String(this.featured.text);
      });
    },
    trashSend() {
      this.featured.remove = false;
      axios.delete('/api/queue/item', { data: { ID: this.featured.ID } }).then(() => {
        this.tasks[this.key].featured = false;
        this.loader = false;
      });
    },
    copyKey(token, key) {
      try {
        navigator.clipboard.writeText(token);
        this.$refs.copy[key].innerText = 'Copied!';
      } catch ($e) {
        //
      }
    },
  },
};
</script>

<style scoped>
  .all-tasks >>> .v-image__image--cover {
    background-size: auto !important;
    display: list-item;
  }
  .link-green{
    color: #3c763d !important;
  }
  .v-chip.v-size--default {
    border-radius: 23px !important;
    font-size: 16px !important;
    height: 46px !important;
  }
</style>
