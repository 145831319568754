<template lang="pug">
  v-dialog(:value="visible" width="100%" @click:outside="closeDialog"
    @keydown.esc="closeDialog" fullscreen hide-overlay)
    v-card
      v-toolbar(color="success")
        v-btn(icon dark @click="closeDialog")
          v-icon mdi-close
        v-toolbar-title Download from site

      v-card-text
        v-container
          v-row
            v-col(cols="3")
              v-btn(color="success" small :loading="loading" @click="getPublic").wide_button
                v-icon(x-small) mdi-content-copy
                | Copy all images to library
              v-list(dense)
                v-subheader Public libraries
                v-list-item-group(color="success" v-model="selectedItem")
                  v-list-item(v-for="(gallery, key) in galleries" :key="key" @click="setItem(key)")
                    v-list-item-content
                      v-list-item-title(v-text="gallery")
            v-col(cols="9")
              alert-message(ref='alertComponent')
              v-container
                v-row
                  v-col(cols='4' v-for="(image, key) in images" :key="key")
                    .gallery-thumb
                      .thumb-img(@click="visible=true")
                        v-img(:src="image.thumb").img-responsive
                      .thumb-text
                        p {{image.short}}
                        v-btn(color="success" small @click="getImage(image.file, image.title, key)")
                          v-icon(small) mdi-content-copy
                          | copy to library
                        v-icon( v-if="image.coped" small color="success").pl-1 mdi-check
                        v-icon( v-if="image.copedNo" small color="error").pl-1 mdi-cancel
</template>

<script>

import _ from 'lodash';
import axios from 'axios';
import AlertMessage from '../common/AlertMessage';

export default {
  name: 'UploadsPublic',
  components: {
    'alert-message': AlertMessage,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    galleries: {
      type: Array,
      default: () => [],
    },
    libraryId: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      selectedItem: 0,
      selectedGallery: '',
      images: [],
      loading: false,
    };
  },
  watch: {
    galleries() {
      if (this.galleries.length) {
        this.selectedGallery = this.galleries[this.selectedItem];
        this.getImages();
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close');
    },
    setItem(index) {
      this.selectedItem = index;
      if (Number(index) >= 0) {
        this.selectedGallery = this.galleries[index];
        this.getImages();
      }
    },
    getImages() {
      axios.get(`/api/public_gallery/${this.selectedGallery}`).then((response) => {
        this.images = _.get(response, 'data.images', []);
        this.images.forEach((value, index) => {
          this.$set(this.images[index], 'coped', false);
          this.$set(this.images[index], 'copedNo', false);
        });
      });
    },
    getPublic() {
      this.loading = true;
      axios.put(
        '/api/gallery/copy/public_gallery',
        { public_gallery: this.selectedGallery, gallery_id: this.libraryId },
      ).then((response) => {
        if (_.get(response, 'data.success', false)) {
          this.$refs.alertComponent.showSuccess('Success! Images was copped', true);
        } else {
          this.$refs.alertComponent.showError('Something wrong', true);
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    getImage(image, title, key) {
      axios.post(
        '/api/my-image/download',
        { image, caption: title, to_gallery: this.libraryId },
      ).then((response) => {
        if (_.get(response, 'data.success', false)) {
          this.images[key].coped = true;
          this.images[key].copedNo = false;
        } else {
          this.images[key].coped = false;
          this.images[key].copedNo = true;
        }
      });
    },
  },
};
</script>

<style scoped>
  .wide_button{
    width: 100%;
    text-transform: none;
  }
</style>
