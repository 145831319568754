var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.visible, "max-width": "80%" },
      on: {
        "click:outside": _vm.close,
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.close($event)
        }
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { color: "success" } },
            [
              _c(
                "v-btn",
                { attrs: { icon: "", dark: "" }, on: { click: _vm.close } },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", [_vm._v("Pause to X hours")])
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                [
                  _c("v-text-field", {
                    staticClass: "default",
                    attrs: {
                      type: "number",
                      required: "",
                      label: "Hours:",
                      min: "1"
                    },
                    model: {
                      value: _vm.hours,
                      callback: function($$v) {
                        _vm.hours = $$v
                      },
                      expression: "hours"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { color: "success", text: "" },
                  on: { click: _vm.pause }
                },
                [_vm._v("YES, SAVE")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { color: "error", text: "" },
                  on: { click: _vm.close }
                },
                [_vm._v("NO, CANCEL")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }