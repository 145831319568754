<template lang="pug">
  div
    v-alert(
      v-model="success"
      dismissible
      outlined
      type="success"
      dense
      transition="scale-transition"
    )
      | {{ message_success }}
    v-alert(
      v-model="error"
      dismissible
      outlined
      type="error"
      dense
      transition="scale-transition"
    )
      | {{ message_error }}
</template>

<script>
export default {
  name: 'AlertMessage',
  data() {
    return {
      success: false,
      error: false,
      message_success: '',
      message_error: '',
    };
  },
  methods: {
    showSuccess(message, auto = false) {
      this.success = false;
      setTimeout(() => {
        this.success = true;
      }, 700);
      this.message_success = message;
      if (auto) {
        this.autoHide();
      }
    },
    showError(message, auto = false) {
      this.error = false;
      setTimeout(() => {
        this.error = true;
      }, 700);
      this.message_error = message;
      if (auto) {
        this.autoHide();
      }
    },
    hideMessages() {
      this.success = false;
      this.error = false;
    },
    autoHide() {
      setTimeout(() => {
        this.success = false;
        this.error = false;
      }, 4000);
    },
  },
};
</script>

<style scoped>

</style>
