<template lang="pug">
  v-dialog(:value="visible" max-width="290" @click:outside="close"
    @keydown.esc="close")
    v-card
      v-toolbar(color="success")
        v-btn(icon dark @click="close")
          v-icon mdi-close
        v-toolbar-title Remove item
      v-card-text Do you want to remove the item
      v-card-text(v-if="item") {{item}}
      v-divider
      v-card-actions
        v-spacer
        v-btn(color="green darken-1"
          text
          @click="$emit('accept')") OK
        v-btn(color="green darken-1"
          text
          @click="$emit('close')") Cancel
</template>

<script>
export default {
  name: 'RemoveDialogue',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    item: {
      type: String,
      default: '',
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>

</style>
