<template lang="pug">
  v-app(id="dropbox" v-show="visible")
    v-dialog(v-model="visible" scrollable max-width="600px" @click:outside="close"
      @keydown.esc="close")
      v-card
        v-card-title
          v-spacer
          img(src="/images/setting/icon-dropbox-blue.png" style="max-width: 36px;")
          | &nbsp;Dropbox
          v-spacer
        v-breadcrumbs(:items="breadcrumbs")
          template(v-slot:item="{ item }")
            v-breadcrumbs-item(
              :href="item.href"
              :disabled="item.disabled"
              @click="lessBreadcrumbs($event, item)"
            ) {{ item.text }}
        v-progress-linear(
          v-show="loader"
          color="warning"
          indeterminate
          rounded
          height="6")
        v-card-text(style="height: 600px;")
          v-list(shaped)
            v-list-item-group(color="success" v-model="index")
              v-list-item(v-for="(item, key) in list" :key="key" @click="setItem(key)")
                v-list-item-icon
                  v-icon mdi-folder
                v-list-item-content
                  v-list-item-title(v-text="item.name")
        v-card-actions
          v-spacer
          v-btn(
            @click="selectFolder"
            color="success"
            small
          ) Select the Current Folder
          v-spacer
</template>

<script>
/* eslint no-undef: "off" */
import axios from 'axios';

export default {
  name: 'Dropbox',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loader: false,
      index: 0,
      selectedItem: '',
      selectedFolder: '',
      list: {},
      breadcrumbs: [
        {
          text: 'Dropbox',
          disabled: true,
          href: '/',
          level: 0,
        },
      ],
    };
  },

  watch: {
    visible() {
      if (this.visible && !this.list.length) {
        this.getList();
      }
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      if (window.dropboxDialog) {
        this.dialog = window.dropboxDialog;
        window.dropboxDialog = false;
      }
    }, 1500);
  },

  methods: {
    setItem(index) {
      this.selectedItem = index;
      if (Number(index) >= 0) {
        this.selectedFolder = this.list[index].path;
        this.selectedFolederName = this.list[index].name;
      }
      this.getList(this.selectedFolder);
    },

    getList(path = '/', breadckrumbs = true) {
      this.loader = true;
      axios.post('/api/dropbox/catalog', { path }).then((response) => {
        this.loader = false;
        if (response.data.length) {
          this.list = response.data;
        }
        this.makeBreadCrumbs(path, response.data, breadckrumbs);
      }).catch(() => {
        this.loader = false;
      });
    },

    makeBreadCrumbs(path, data, breadckrumbs) {
      if (path !== '/') {
        if (data.length) {
          this.index = -1;
        }
        if (data.length && breadckrumbs) {
          this.addBreadcrumbs(path);
        }
      } else {
        this.index = 0;
        this.selectedFolder = this.list[0].path;
      }
    },
    selectFolder() {
      this.$emit('submit', this.selectedFolder);
    },

    addBreadcrumbs(path) {
      const maxIndex = this.breadcrumbs.length - 1;
      const link = {
        text: this.selectedFolederName,
        disabled: true,
        href: path,
        level: Number(maxIndex) + 1,
      };
      this.breadcrumbs[maxIndex].disabled = false;
      this.breadcrumbs.push(link);
    },
    lessBreadcrumbs(e, item) {
      e.preventDefault();
      this.breadcrumbs[item.level].disabled = true;
      this.breadcrumbs.length = Number(item.level) + 1;
      this.selectedFolder = item.href;
      this.getList(item.href, false);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>

</style>
