var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("confirm-dialog", { ref: "confirmDialog" }),
      _c(
        "v-container",
        [
          _c("h2", [_vm._v("Agency")]),
          _c(
            "v-col",
            { staticClass: "white-board", attrs: { cols: "12" } },
            [
              _c("h3", [_vm._v("Registration via link")]),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          value: _vm.link,
                          disabled: "",
                          outlined: "",
                          height: "44px"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { color: "grey", large: "" },
                          on: { click: _vm.copyLink }
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-content-copy")]),
                          _c("span", { ref: "copy" }, [_vm._v("Copy")])
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "success", large: "" },
                          on: { click: _vm.createLink }
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-link")]),
                          _vm._v("Create link of registration")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "pt-4" },
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "v-container",
                    { staticClass: "white-board", attrs: { fluid: "" } },
                    [
                      _c("h3", [_vm._v("Share libraries")]),
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item-group",
                            _vm._l(_vm.libraries, function(library, key) {
                              return _c(
                                "v-list-item",
                                { key: key },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", {
                                        domProps: {
                                          textContent: _vm._s(library.name)
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-action",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mb-1",
                                          attrs: {
                                            small: "",
                                            color: "success"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.share(library.ID)
                                            }
                                          }
                                        },
                                        [
                                          _c("v-icon", [_vm._v("mdi-share")]),
                                          _vm._v("Share")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "8" } },
                [
                  _c(
                    "v-container",
                    { staticClass: "white-board", attrs: { fluid: "" } },
                    [
                      _c("h3", [_vm._v("Agency members")]),
                      _c("alert-message", { ref: "alerts" }),
                      _c("v-simple-table", {
                        attrs: { "show-select": "" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function() {
                              return [
                                _c("thead", [
                                  _c("tr", [
                                    _c(
                                      "th",
                                      { staticClass: "text-left" },
                                      [
                                        _c("v-checkbox", {
                                          attrs: { color: "success" },
                                          on: { change: _vm.setAll }
                                        })
                                      ],
                                      1
                                    ),
                                    _c("th", [_vm._v("Email")]),
                                    _c("th", [_vm._v("Account")]),
                                    _c("th", [_vm._v("Action")]),
                                    _c("th", { staticClass: "text-right" }, [
                                      _vm._v("Exclude")
                                    ])
                                  ])
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(_vm.members, function(member, key) {
                                    return _c("tr", { key: key }, [
                                      _c(
                                        "td",
                                        [
                                          _c("v-checkbox", {
                                            attrs: { color: "success" },
                                            model: {
                                              value: member.checkbox,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  member,
                                                  "checkbox",
                                                  $$v
                                                )
                                              },
                                              expression: "member.checkbox"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("td", [_vm._v(_vm._s(member.email))]),
                                      _c("td", [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                "/users/makeLogin/" + member.ID
                                            }
                                          },
                                          [_vm._v("Login as")]
                                        )
                                      ]),
                                      _c(
                                        "td",
                                        [
                                          member.mode === "agency"
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color: "grey"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.detach(
                                                        member.email,
                                                        key
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [_vm._v("mdi-minus")]
                                                  ),
                                                  _vm._v("Detach")
                                                ],
                                                1
                                              )
                                            : _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color: "success"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.attach(
                                                        member.email,
                                                        key
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [_vm._v("mdi-plus")]
                                                  ),
                                                  _vm._v("Attach")
                                                ],
                                                1
                                              )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "error"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.exclude(
                                                    member.email,
                                                    key
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v("mdi-delete")]
                                              ),
                                              _vm._v("Exclude")
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  }),
                                  0
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.selected,
                          callback: function($$v) {
                            _vm.selected = $$v
                          },
                          expression: "selected"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }