var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible"
        }
      ],
      attrs: { id: "dropbox" }
    },
    [
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", "max-width": "600px" },
          on: {
            "click:outside": _vm.close,
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.close($event)
            }
          },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c("v-spacer"),
                  _c("img", {
                    staticStyle: { "max-width": "36px" },
                    attrs: { src: "/images/setting/icon-dropbox-blue.png" }
                  }),
                  _vm._v(" Dropbox"),
                  _c("v-spacer")
                ],
                1
              ),
              _c("v-breadcrumbs", {
                attrs: { items: _vm.breadcrumbs },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-breadcrumbs-item",
                          {
                            attrs: { href: item.href, disabled: item.disabled },
                            on: {
                              click: function($event) {
                                return _vm.lessBreadcrumbs($event, item)
                              }
                            }
                          },
                          [_vm._v(_vm._s(item.text))]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("v-progress-linear", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.loader,
                    expression: "loader"
                  }
                ],
                attrs: {
                  color: "warning",
                  indeterminate: "",
                  rounded: "",
                  height: "6"
                }
              }),
              _c(
                "v-card-text",
                { staticStyle: { height: "600px" } },
                [
                  _c(
                    "v-list",
                    { attrs: { shaped: "" } },
                    [
                      _c(
                        "v-list-item-group",
                        {
                          attrs: { color: "success" },
                          model: {
                            value: _vm.index,
                            callback: function($$v) {
                              _vm.index = $$v
                            },
                            expression: "index"
                          }
                        },
                        _vm._l(_vm.list, function(item, key) {
                          return _c(
                            "v-list-item",
                            {
                              key: key,
                              on: {
                                click: function($event) {
                                  return _vm.setItem(key)
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-folder")])],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", {
                                    domProps: { textContent: _vm._s(item.name) }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "success", small: "" },
                      on: { click: _vm.selectFolder }
                    },
                    [_vm._v("Select the Current Folder")]
                  ),
                  _c("v-spacer")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }