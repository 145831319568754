var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    block: "",
                    color: "success",
                    loading: _vm.copyLoader
                  },
                  on: { click: _vm.getPublic }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-content-copy")
                  ]),
                  _vm._v("Get selected library")
                ],
                1
              ),
              _c("div"),
              _c(
                "v-list",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-list-item-group",
                    {
                      attrs: { color: "success" },
                      model: {
                        value: _vm.selectedItem,
                        callback: function($$v) {
                          _vm.selectedItem = $$v
                        },
                        expression: "selectedItem"
                      }
                    },
                    _vm._l(_vm.galleries, function(gallery, key) {
                      return _c(
                        "v-list-item",
                        {
                          key: key,
                          on: {
                            click: function($event) {
                              return _vm.setItem(key)
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                domProps: { textContent: _vm._s(gallery) }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [
              _c("alert-message", { ref: "alertComponent" }),
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-progress-linear", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.loading,
                            expression: "loading"
                          }
                        ],
                        attrs: {
                          color: "warning",
                          indeterminate: "",
                          rounded: "",
                          height: "6"
                        }
                      }),
                      _vm._l(_vm.images, function(image, key) {
                        return _c(
                          "v-col",
                          { key: key, attrs: { cols: "3" } },
                          [
                            _c(
                              "v-card",
                              [
                                _c("v-img", {
                                  staticClass: "grey darken-4",
                                  attrs: { src: image.thumb }
                                }),
                                _c("v-card-title", { staticClass: "caption" }, [
                                  _vm._v(_vm._s(image.short))
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }