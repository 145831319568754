<template lang="pug">
  div.white-board
    v-row.b-white
      v-col(cols="2").pa-0
        v-card
          v-alert(v-show="taskData.paused"
            type="error"
            color="warning"
            border="right"
            text
            dense
            :title="`This task is paused. ${taskData.paused} failed attempts."+
            "Reason: ${taskData.info}`"
          ).mb-0 Failed...
          v-card-text.d-flex.align-center.justify-center.social_image
            img(:src="taskData.sn_image" :title="taskData.page_name" style="max-height: 100px;")
          v-card-title.subtitle-2
            div(:class="taskData.social_ico").fab
            a(:href="taskData.sn_link" target='_blank').link &nbsp; {{ taskData.page_name }}
              span(v-show="taskData.type === 'group'") (group)
      v-col(cols="10").pl-10
        v-container.pl-0
          alert-message(ref='alertComponent')
          p Select Content:
          v-btn(large color="grey" @click.native="showDropbox=true").mr-5
            v-icon(color="black") mdi-dropbox
            | DROPBOX
          v-btn(large color="grey" @click.native="showLibrary=true").mr-5
            v-icon(color="black") mdi-image-multiple
            | LIBRARY
          v-btn(large color="grey" @click.native="showWordpress=true").mr-5
            v-icon(color="black") mdi-wordpress
            | WORDPRESS
        v-container.pl-0
          span Publishing Mix:
          v-list
            template(v-for="(source, sourceKey) in taskData.sources")
              v-divider(v-show="sourceKey !== 0").ma-0
              v-list-item(style="min-height: auto !important;")
                v-list-item-avatar(color="grey").lite.source
                  v-icon(color="black") {{sourceIcon(source)}}
                v-list-item-content.pa-1
                  v-list-item-title(v-text="`${sourceType(source)} : ${sourceName(source)}`")
                v-list-item-action
                  v-text-field(
                    type="number"
                    :value="source.percent"
                    @input="setPercent(sourceKey, $event)"
                    style="max-width: 80px;"
                    max="100"
                    min="0"
                    solo
                  )
                  p %
                  v-btn(icon color="error" @click.native="removeSource(sourceKey)")
                    v-icon() mdi-delete
        v-col(cols="6")
          span Select Frequency:
          v-select(
            :items="plans"
            v-model="taskData.time"
            item-text="value"
            item-value="key"
            solo
            )
        v-row
          v-col(cols="6")
            span Recycle Posts?
            v-radio-group(@change="setPublish($event)" :value="taskData.do_not_delete" row)
              v-radio(color="success" label="Yes" :value="1")
              v-radio(color="success" label="No" :value="0")
              v-radio(color="success" label="Custom" :value="def.CUSTOM")
          v-col(cols="5" v-if="taskData.do_not_delete === def.CUSTOM")
            span.text-success Times: &nbsp;
            v-text-field(
              @input="setCount($event)"
              type="number"
              :value="taskData.posted_count"
              style="max-width: 80px;"
              min="0"
              solo
            ).d-inline-block
    .justify-center.d-flex
      v-btn(large color="success" @click="save(taskData.ID)").mx-1
        v-icon mdi-content-save
        | Save
      v-btn(large color="error" @click="remove(taskData.ID)").mx-1
        v-icon mdi-delete
        | Delete

    wordpress-dialog(
      :visible="showWordpress"
      @submit="submitWordpress"
      @close="showWordpress=false"
    )
    library-dialog(
      :visible="showLibrary"
      :libraries="libraries"
      @submit="submitLibrary"
      @close="showLibrary=false"
    )
    dropbox(:visible="showDropbox" @submit="submitDropbox" @close="showDropbox=false")
</template>

<script>
import axios from 'axios';
import _ from 'lodash';

import main from '../../mixins/main';
import AlertMessage from '../common/AlertMessage';
import WordpressDialog from './WordpressDialog';
import LibraryDialog from './LibraryDialog';
import Dropbox from './dropbox';

const DEFINE = {
  CUSTOM: 2,
  INSTAGRAM: 'instagram',
};

export default {
  name: 'Task',
  components: {
    AlertMessage,
    WordpressDialog,
    LibraryDialog,
    Dropbox,
  },
  mixins: [main],
  props: {
    task: {
      type: Object,
      default: () => {},
    },
    libraries: {
      type: Array,
      default: () => [],
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      plans: [
        { key: '30', value: 'Every 30 mins' },
        { key: '1', value: 'Every 1 hour' },
        { key: '2', value: 'Every 2 hours' },
        { key: '3', value: 'Every 3 hours' },
        { key: '4', value: 'Every 4 hours' },
        { key: '5', value: 'Every 5 hours' },
        { key: '6', value: 'Every 6 hours' },
        { key: '8', value: 'Every 8 hours' },
        { key: '12', value: 'Twice a Day' },
        { key: '0', value: 'Once a Day' },
        { key: '48', value: 'Every 2 Days' },
        { key: '72', value: 'Every 3 Days' },
        { key: '96', value: 'Every 4 Days' },
        { key: '7', value: 'Once a Week' },
      ],
      publish: 0,
      taskData: this.task,
      showLibrary: false,
      showWordpress: false,
      showDropbox: false,
    };
  },
  computed: {
    def() { return DEFINE; },
  },
  watch: {
    task() {
      this.taskData = this.task;
    },
  },
  created() {
    this.setPlans();
  },
  methods: {
    newItem(folder = '0', gallery = null, wordpress = null, galleryName = '') {
      const item = {};
      item.id = Date.now().toString().slice(0, -3);
      item.SHID = 0;
      item.cloud_folder = folder;
      item.user_gallery = gallery;
      item.gallery_name = galleryName;
      item.wordpress = wordpress;
      item.percent = 100;
      this.taskData.sources.push(...[item]);
    },
    save(id) {
      if (!this.taskData.sources.length) {
        this.$refs.alertComponent.showError('Any source(Dropbox, Wordpress, Library) is not selected');
        return false;
      }
      const data = {
        page: this.taskData.page_id,
        posttime: this.taskData.time,
        do_not_delete: this.taskData.do_not_delete,
        posted_count: this.taskData.do_not_delete > 1 ? this.taskData.posted_count : 0,
        sources: JSON.stringify(this.taskData.sources),
        id,
        uid: 99,
      };
      axios.post('/api/setting/psetup', data).then((response) => {
        if (_.get(response, 'data.success', false)) {
          this.$emit('take', id);
        }
      });
      return true;
    },
    remove(id) {
      let url = '';
      if (this.edit) {
        url = `/api/setting/pause/${this.taskData.page_id}`;
      } else {
        url = `/api/setting/delete/${this.taskData.page_id}`;
      }
      axios.delete(url).then((response) => {
        if (_.get(response, 'data.success', false)) {
          this.$emit('take', id);
        }
      });
    },
    setPlans() {
      if (this.task.type === this.def.INSTAGRAM) { // remove 30 min plan for Instagram
        const index = this.plans.map((item) => String(item.key))
          .indexOf('30');
        this.plans.splice(index, 1);
      }
    },
    setPublish(value) {
      this.taskData.do_not_delete = value;
      if (value !== this.def.CUSTOM) {
        this.setCount(0);
      }
    },
    setCount(value) {
      this.taskData.posted_count = Number(value);
    },
    setPercent(key, value) {
      this.taskData.sources[key].percent = value;
    },
    removeSource(sourceKey) {
      this.taskData.sources.splice(sourceKey, 1);
    },

    submitDropbox(folder) {
      this.showDropbox = false;
      this.newItem(folder, null, null);
    },
    submitLibrary(data) {
      this.showLibrary = false;
      const libraryId = data.id;
      const libraryName = data.name;
      this.newItem('0', libraryId, null, libraryName);
    },
    submitWordpress() {
      this.showWordpress = false;
      this.newItem('0', null, 1);
    },
  },
};
</script>

<style scoped>
  .v-select >>> .v-input__slot {
    background-color: #dfe3ee !important;
  }
</style>
<style>

</style>
