<template lang="pug">
  v-dialog(:value="visible" width="500" @click:outside="close" @keydown.esc="close")
    v-card
      v-toolbar(color="success")
        v-btn(icon dark @click="close")
          v-icon mdi-close
        v-toolbar-title Edit
      v-card-text.mt-1
        v-checkbox(@change="copy" label='Copy changes to library' color="success")
        v-textarea(
          outlined
          name="text"
          label="Text"
          :value="text"
          @input="handleInput"
          color="success"
          )
      v-card-actions
        v-spacer
        v-btn(color="green darken-1"
          text
          @click="$emit('accept')") Save
        v-btn(color="green darken-1"
          text
          @click="close") Cancel
</template>

<script>
export default {
  name: 'EditDialogue',

  model: {
    prop: 'text',
    event: 'blur',
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
  },

  methods: {
    handleInput(value) {
      this.$emit('blur', value);
    },
    close() {
      this.$emit('close');
    },
    copy() {
      this.$emit('copy');
    },
  },
};
</script>

<style scoped>

</style>
