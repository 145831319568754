import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: '#1867c0',
        secondary: '#607d8b',
        accent: '#673ab7',
        error: '#e64c3c',
        warning: '#ff9800',
        info: '#00bcd4',
        success: '#338e4c',
        black: '#16232e',
        lite: '#f1f1f1',
        grey: '#dfe3ee',
      },
    },
  },
  icons: {
    iconfont: 'mdi',
  },
};

export default new Vuetify(opts);
