var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.visible, width: "500" },
      on: {
        "click:outside": _vm.close,
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.close($event)
        }
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { color: "success" } },
            [
              _c(
                "v-btn",
                { attrs: { icon: "", dark: "" }, on: { click: _vm.close } },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", [_vm._v("Edit")])
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "mt-1" },
            [
              _c("v-checkbox", {
                attrs: { label: "Copy changes to library", color: "success" },
                on: { change: _vm.copy }
              }),
              _c("v-textarea", {
                attrs: {
                  outlined: "",
                  name: "text",
                  label: "Text",
                  value: _vm.text,
                  color: "success"
                },
                on: { input: _vm.handleInput }
              })
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "green darken-1", text: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("accept")
                    }
                  }
                },
                [_vm._v("Save")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "green darken-1", text: "" },
                  on: { click: _vm.close }
                },
                [_vm._v("Cancel")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }