<template lang="pug">
  v-dialog(:value="visible" width="600" @click:outside="close"
    @keydown.esc="close")
    v-card
      v-toolbar(color="success")
        v-btn(icon dark @click="close")
          v-icon mdi-close
        v-toolbar-title Instagram limitations
      v-card-text
        v-simple-table
          template(v-slot:default)
            thead
              tr
                th
                th
                th Supported
                th
            tbody
              tr
                td Will publish
                td
                td
                td
                  v-icon(color="red") mdi-close-circle
              tr
                td Format
                td {{ data.format }}
                td {{ data.formats_limit }}
                td
                  v-icon(v-if="data.format_status" color="success") mdi-checkbox-marked-circle
                  v-icon(v-else color="red") mdi-close-circle
              template(v-if="data.video")
                tr
                  td Video duration
                  td {{ data.duration }} s
                  td {{ data.duration_limit }} s
                  td
                    v-icon(v-if="data.duration_status" color="success") mdi-checkbox-marked-circle
                    v-icon(v-else color="red") mdi-close-circle
                tr
                  td Frame rate:
                  td {{ data.fps }} FPS
                  td {{ data.fps_limit }} FPS
                  td
                    v-icon(v-if="data.fps_status" color="success") mdi-checkbox-marked-circle
                    v-icon(v-else color="red") mdi-close-circle
              template(v-else)
                tr
                  td Supported aspect ratio:
                  td Must be within a 4:5 to 1.91:1 range
                  td
              tr
                td Dimension
                td {{ data.dimension }} px
                td
                td
              tr
                td Size
                td {{ data.size_mb }} MB
                td {{ data.size_limit }} MB max
                td
                  v-icon(v-if="data.size_status" color="success") mdi-checkbox-marked-circle
                  v-icon(v-else color="red") mdi-close-circle
              tr
                td Aspect ratio:
                td {{ data.a_ratio }}
                td {{ data.a_ratio_limit }}
                td
                  v-icon(v-if="data.a_ratio_status" color="success") mdi-checkbox-marked-circle
                  v-icon(v-else color="red") mdi-close-circle
        v-container
          v-row
            v-col(cols=2)
              v-icon(color="info") mdi-information
            v-col(cols="10")
              p Solution: Delete item. Upload a supported new file without current limitation!
      v-card-actions

</template>

<script>
export default {
  name: 'LimitationInstagram',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: null,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>

</style>
