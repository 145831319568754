<template lang="pug">
  v-app
    v-row
      v-col(cols="3")
        v-btn(block color="success" @click="getPublic" :loading="copyLoader")
          v-icon(left) mdi-content-copy
          | Get selected library
        div
        v-list(dense)
          v-list-item-group(color="success" v-model="selectedItem")
            v-list-item(v-for="(gallery, key) in galleries" :key="key" @click="setItem(key)")
              v-list-item-content
                v-list-item-title(v-text="gallery")
      v-col(cols="9")
        alert-message(ref='alertComponent')
        v-container
          v-row
            v-progress-linear(
              v-show="loading"
              color="warning"
              indeterminate
              rounded
              height="6")
            v-col(cols="3" v-for="(image, key) in images" :key="key")
              v-card
                v-img(:src="image.thumb").grey.darken-4
                v-card-title.caption {{ image.short }}
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import AlertMessage from '../common/AlertMessage';

export default {
  name: 'PublicLibrary',
  components: {
    'alert-message': AlertMessage,
  },
  data() {
    return {
      selectedItem: 0,
      selectedGallery: '',
      images: [],
      index: 0,
      loading: false,
      copyLoader: false,
    };
  },
  computed: {
    galleries() {
      return this.$store.state.publicGalleries;
    },
  },
  watch: {
    galleries() {
      this.setItem(0);
    },
    selectedItem() {
      if (typeof this.selectedItem === 'undefined') {
        setTimeout(() => {
          this.selectedItem = this.index;
        }, 500);
      }
    },
  },
  created() {
    this.$store.dispatch('getData');
  },
  methods: {
    setItem(index) {
      this.index = index;
      this.selectedGallery = this.galleries[index];
      this.getImages();
    },
    getImages() {
      this.loading = true;
      axios.get(`/api/public_gallery/${this.selectedGallery}`).then((response) => {
        this.images = _.get(response, 'data.images', []);
      }).finally(() => {
        this.loading = false;
      });
    },
    getPublic() {
      this.copyLoader = true;
      axios.put(`/api/public_gallery/${this.selectedGallery}`).then((response) => {
        if (_.get(response, 'data.success', false)) {
          this.$refs.alertComponent.showSuccess('Success! The library was copped', true);
        } else {
          this.$refs.alertComponent.showError('Something wrong', true);
        }
      }).finally(() => {
        this.copyLoader = false;
      });
    },
  },
};
</script>
