<template lang="pug">
  v-app
    confirm-dialog(ref='confirmDialog')
    v-container
      h2 Agency
      v-col(cols=12).white-board
        h3 Registration via link
        v-row
          v-col(cols=6)
            v-text-field(:value="link" disabled outlined height="44px" )
          v-col(cols=6)
            v-btn(color="grey" @click="copyLink" large).mr-2
              v-icon mdi-content-copy
              span(ref='copy') Copy
            v-btn(color='success' @click="createLink" large)
              v-icon mdi-link
              | Create link of registration
      v-row.pt-4
        v-col(cols=4)
          v-container(fluid).white-board
            h3 Share libraries
            v-list
              v-list-item-group
                v-list-item(v-for="(library, key) in libraries" :key="key")
                  v-list-item-content
                    v-list-item-title(v-text="library.name")
                  v-list-item-action
                    v-btn(small color="success" @click="share(library.ID)").mb-1
                      v-icon() mdi-share
                      | Share
        v-col(cols=8)
          v-container(fluid).white-board
            h3 Agency members
            alert-message(ref="alerts")
            v-simple-table(show-select v-model="selected")
              template(v-slot:default)
                thead
                  tr
                    th.text-left
                      v-checkbox(@change="setAll" color="success")
                    th Email
                    th Account
                    th Action
                    th.text-right Exclude
                tbody
                  tr(v-for="(member, key) in members" :key="key")
                    td
                      v-checkbox(v-model="member.checkbox" color="success")
                    td {{member.email}}
                    td
                      a(:href="'/users/makeLogin/' +member.ID") Login as
                    td
                      v-btn(
                        v-if="member.mode === 'agency'"
                        small
                        color="grey"
                        @click="detach(member.email, key)"
                      )
                        v-icon(small) mdi-minus
                        | Detach
                      v-btn(v-else small color="success" @click="attach(member.email, key)")
                        v-icon(small) mdi-plus
                        | Attach

                    td
                      v-btn(small color="error" @click="exclude(member.email, key)")
                        v-icon(small) mdi-delete
                        | Exclude

</template>

<script>

import _ from 'lodash';
import axios from 'axios';
import Vue from 'vue';
import ConfirmDialogue from '../common/ConfirmDialogue';
import AlertMessage from '../common/AlertMessage';

export default {
  name: 'Agency',
  components: {
    'confirm-dialog': ConfirmDialogue,
    'alert-message': AlertMessage,
  },
  data() {
    return {
      link: '',
      libraries: {},
      members: {},
      selected: [],
      all: false,
      detachDialog: false,
      excludeDialog: false,
    };
  },
  created() {
    this.main();
  },
  methods: {
    copyLink() {
      try {
        navigator.clipboard.writeText(this.link);
        this.$refs.copy.innerText = 'Copied!';
      } catch ($e) {
        //
      }
    },
    main() {
      axios.get('/api/agency/main').then((response) => {
        this.libraries = _.get(response, 'data.libraries', {});
        this.members = _.get(response, 'data.members', {});
        this.members.forEach((value, index) => {
          this.$set(this.members[index], 'checkbox', false);
        });
      });
    },
    share(id) {
      const emails = [];
      this.members.forEach((member) => {
        if (member.checkbox) {
          emails.push(member.email);
        }
      });

      if (!emails.length) {
        this.$refs.alerts.showError('No accounts selected!', true);
        return false;
      }
      axios.post('/api/agency/copyLib', { library_id: id, emails: JSON.stringify(emails) }).then(() => {
        this.$refs.alerts.showSuccess('The library was copied!', true);
      });
      return true;
    },
    createLink() {
      axios.get('/api/agency/link').then((response) => {
        this.link = _.get(response, 'data.link', '');
      });
    },
    setAll() {
      this.all = !this.all;
      this.members.forEach((value, index) => {
        this.members[index].checkbox = this.all;
      });
    },
    async attach(email, key) {
      axios.post('/api/agency/attach', { email }).then(() => {
        this.members[key].mode = 'agency';
      });
    },
    async detach(email, key) {
      const ok = await this.$refs.confirmDialog.show({
        ask: 'Do you want to detach the following user from list?',
        text: email,
      });
      if (ok) {
        axios.post('/api/agency/detach', { email }).then(() => {
          this.members[key].mode = 'user';
        });
      }
    },
    async exclude(email, key) {
      const submit = await this.$refs.confirmDialog.show({
        ask: 'Do you want to exclude the following user from list?',
        text: email,
      });
      if (submit) {
        axios.post('/api/agency/exclude', { email }).then(() => {
          Vue.delete(this.members, key);
        });
      }
    },
  },
};
</script>

<style scoped>
.v-list-item{
  padding-right: 0 !important;
  padding-left: 0 !important;
}
</style>
