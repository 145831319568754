var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _vm.loader ? _c("loader") : _vm._e(),
      _c("div", { staticClass: "main-content" }, [
        _c("h2", [_vm._v("Reconnect Socials/Cloud")]),
        _c(
          "div",
          { staticClass: "col-md-8 content_center" },
          [
            _c("v-simple-table", {
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function() {
                    return [
                      _c("thead", [
                        _c("tr", [
                          _c("th", { staticClass: "text-left" }, [
                            _vm._v("Socials/Cloud")
                          ]),
                          _c("th", { staticClass: "text-left" }, [
                            _vm._v("Token")
                          ]),
                          _c("th", { staticClass: "text-left" }, [
                            _vm._v("Expires at")
                          ]),
                          _c("th", { staticClass: "text-center" }, [
                            _vm._v("Reconnect")
                          ])
                        ])
                      ]),
                      _c("tbody", [
                        _c(
                          "tr",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.dropbox.connected,
                                expression: "dropbox.connected"
                              }
                            ]
                          },
                          [
                            _c("td", [
                              _c("img", {
                                staticStyle: { "max-width": "36px" },
                                attrs: {
                                  src: "/images/setting/icon-dropbox-blue.png"
                                }
                              }),
                              _vm._v(" Dropbox")
                            ]),
                            _c(
                              "td",
                              [
                                _vm._v(_vm._s(_vm.dropbox.status)),
                                _vm.dropbox.status === "ok"
                                  ? _c(
                                      "v-icon",
                                      { attrs: { color: "success" } },
                                      [_vm._v("mdi-checkbox-marked-circle")]
                                    )
                                  : _vm.dropbox.status === "failed"
                                  ? _c("v-icon", { attrs: { color: "red" } }, [
                                      _vm._v("mdi-alert-circle")
                                    ])
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c("td", [_vm._v(_vm._s(_vm.dropbox.expires_at))]),
                            _c(
                              "td",
                              { staticClass: "text-center" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ma-2",
                                    attrs: {
                                      outlined: "",
                                      color: "success",
                                      href: _vm.dropbox.url
                                    },
                                    on: { click: function($event) {} }
                                  },
                                  [
                                    _vm._v("Reconnect"),
                                    _c(
                                      "v-icon",
                                      { attrs: { right: "", dark: "" } },
                                      [_vm._v("mdi-restart")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "tr",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.facebook.connected,
                                expression: "facebook.connected"
                              }
                            ]
                          },
                          [
                            _c(
                              "td",
                              [
                                _c("v-avatar", { attrs: { size: "36px" } }, [
                                  _c("img", {
                                    attrs: {
                                      src: "/images/setting/icono-fb.png"
                                    }
                                  })
                                ]),
                                _vm._v(" Facebook")
                              ],
                              1
                            ),
                            _c(
                              "td",
                              [
                                _vm._v(_vm._s(_vm.facebook.status)),
                                _vm.facebook.status === "ok"
                                  ? _c(
                                      "v-icon",
                                      { attrs: { color: "success" } },
                                      [_vm._v("mdi-checkbox-marked-circle")]
                                    )
                                  : _vm.facebook.status === "failed"
                                  ? _c("v-icon", { attrs: { color: "red" } }, [
                                      _vm._v("mdi-alert-circle")
                                    ])
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c("td", [_vm._v(_vm._s(_vm.facebook.expires_at))]),
                            _c(
                              "td",
                              { staticClass: "text-center" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ma-2",
                                    attrs: {
                                      outlined: "",
                                      color: "success",
                                      href: "/api/fb/redirect"
                                    },
                                    on: { click: function($event) {} }
                                  },
                                  [
                                    _vm._v("Reconnect"),
                                    _c(
                                      "v-icon",
                                      { attrs: { right: "", dark: "" } },
                                      [_vm._v("mdi-restart")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "tr",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.twitter.connected,
                                expression: "twitter.connected"
                              }
                            ]
                          },
                          [
                            _c(
                              "td",
                              [
                                _c("v-avatar", { attrs: { size: "36px" } }, [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "/images/setting/circle-twitter-512.png"
                                    }
                                  })
                                ]),
                                _vm._v(" Twitter")
                              ],
                              1
                            ),
                            _c(
                              "td",
                              [
                                _vm._v(_vm._s(_vm.twitter.status)),
                                _vm.twitter.status === "ok"
                                  ? _c(
                                      "v-icon",
                                      { attrs: { color: "success" } },
                                      [_vm._v("mdi-checkbox-marked-circle")]
                                    )
                                  : _vm.twitter.status === "failed"
                                  ? _c("v-icon", { attrs: { color: "red" } }, [
                                      _vm._v("mdi-alert-circle")
                                    ])
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c("td", [_vm._v(_vm._s(_vm.twitter.expires_at))]),
                            _c(
                              "td",
                              { staticClass: "text-center" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ma-2",
                                    attrs: {
                                      outlined: "",
                                      color: "success",
                                      href: "/api/twitter-redirect"
                                    },
                                    on: { click: function($event) {} }
                                  },
                                  [
                                    _vm._v("Reconnect"),
                                    _c(
                                      "v-icon",
                                      { attrs: { right: "", dark: "" } },
                                      [_vm._v("mdi-restart")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "tr",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.linkedIn.connected,
                                expression: "linkedIn.connected"
                              }
                            ]
                          },
                          [
                            _c(
                              "td",
                              [
                                _c("v-avatar", { attrs: { size: "36px" } }, [
                                  _c("img", {
                                    attrs: { src: "/images/setting/lnkdin.png" }
                                  })
                                ]),
                                _vm._v(" LinkedIn")
                              ],
                              1
                            ),
                            _c(
                              "td",
                              [
                                _vm._v(_vm._s(_vm.linkedIn.status)),
                                _vm.linkedIn.status === "ok"
                                  ? _c(
                                      "v-icon",
                                      { attrs: { color: "success" } },
                                      [_vm._v("mdi-checkbox-marked-circle")]
                                    )
                                  : _vm.linkedIn.status === "failed"
                                  ? _c("v-icon", { attrs: { color: "red" } }, [
                                      _vm._v("mdi-alert-circle")
                                    ])
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c("td", [_vm._v(_vm._s(_vm.linkedIn.expires_at))]),
                            _c(
                              "td",
                              { staticClass: "text-center" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ma-2",
                                    attrs: {
                                      outlined: "",
                                      color: "success",
                                      href: "/api/linkedin/redirect"
                                    },
                                    on: { click: function($event) {} }
                                  },
                                  [
                                    _vm._v("Reconnect"),
                                    _c(
                                      "v-icon",
                                      { attrs: { right: "", dark: "" } },
                                      [_vm._v("mdi-restart")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ])
                    ]
                  },
                  proxy: true
                }
              ])
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }