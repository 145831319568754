var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("nav-bar", { attrs: { active: 1 } }),
      _c("h3", [_vm._v("No images")]),
      _c("v-progress-linear", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        attrs: { color: "warning", indeterminate: "", rounded: "", height: "6" }
      }),
      _c(
        "v-tabs",
        { attrs: { color: "success" } },
        [
          _c(
            "v-tab",
            {
              on: {
                click: function($event) {
                  _vm.tab = "lib"
                }
              }
            },
            [_vm._v("Library(" + _vm._s(_vm.libraryData.length) + ")")]
          ),
          _c(
            "v-tab",
            {
              on: {
                click: function($event) {
                  _vm.tab = "queue"
                }
              }
            },
            [_vm._v("Queue(" + _vm._s(_vm.queueData.length) + ")")]
          ),
          _c(
            "v-tab",
            {
              on: {
                click: function($event) {
                  _vm.tab = "dropbox"
                }
              }
            },
            [_vm._v("Dropbox(" + _vm._s(_vm.dropboxData.length) + ")")]
          ),
          _c(
            "v-tab",
            {
              on: {
                click: function($event) {
                  _vm.tab = "public"
                }
              }
            },
            [_vm._v("Public(" + _vm._s(_vm.publicData.length) + ")")]
          )
        ],
        1
      ),
      _vm.tab === "lib"
        ? [
            _c("v-data-table", {
              attrs: {
                headers: _vm.libraryHead,
                items: _vm.libraryData,
                "items-per-page": 15
              }
            })
          ]
        : _vm._e(),
      _vm.tab === "queue"
        ? [
            _c("v-data-table", {
              attrs: {
                headers: _vm.queueHead,
                items: _vm.queueData,
                "items-per-page": 15
              }
            })
          ]
        : _vm._e(),
      _vm.tab === "dropbox"
        ? [
            _c("v-data-table", {
              attrs: {
                headers: _vm.dropboxHead,
                items: _vm.dropboxData,
                "items-per-page": 15
              }
            })
          ]
        : _vm._e(),
      _vm.tab === "public"
        ? [
            _c("v-data-table", {
              attrs: {
                headers: _vm.publicHead,
                items: _vm.publicData,
                "items-per-page": 15
              }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }