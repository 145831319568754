<template lang="pug">
  v-container
    nav-bar(:active="1")
    h3 No images
    v-progress-linear(
      v-show="loading"
      color="warning"
      indeterminate
      rounded
      height="6")
    v-tabs(color='success')
      v-tab(@click="tab = 'lib'") Library({{ libraryData.length }})
      v-tab(@click="tab = 'queue'") Queue({{ queueData.length }})
      v-tab(@click="tab = 'dropbox'") Dropbox({{ dropboxData.length }})
      v-tab(@click="tab = 'public'") Public({{ publicData.length }})
    template(v-if="tab === 'lib'")
      v-data-table(:headers="libraryHead" :items="libraryData" :items-per-page="15")
    template(v-if="tab === 'queue'")
      v-data-table(:headers="queueHead" :items="queueData" :items-per-page="15")
    template(v-if="tab === 'dropbox'")
      v-data-table(:headers="dropboxHead" :items="dropboxData" :items-per-page="15")
    template(v-if="tab === 'public'")
      v-data-table(:headers="publicHead" :items="publicData" :items-per-page="15")

</template>

<script>

import _ from 'lodash';
import axios from 'axios';
import NavBar from './NavBar';

export default {
  name: 'NoImages',
  components: {
    NavBar,
  },
  data() {
    return {
      loading: false,
      tab: 'lib',
      libraryHead: [
        {
          text: 'Id', align: 'start', value: 'id',
        },
        {
          text: 'Path',
          sortable: false,
          value: 'path',
        },
        { text: 'Email', value: 'email' },
      ],
      libraryData: [],
      queueHead: [
        {
          text: 'Id', align: 'start', value: 'id',
        },
        {
          text: 'Path',
          sortable: false,
          value: 'path',
        },
        { text: 'Email', value: 'email' },
      ],
      queueData: [],
      dropboxHead: [
        {
          text: 'Id', align: 'start', value: 'id',
        },
        {
          text: 'Path',
          sortable: false,
          value: 'path',
        },
        { text: 'Email', value: 'email' },
      ],
      dropboxData: [],
      publicHead: [
        {
          text: 'Path',
          align: 'start',
          sortable: false,
          value: 'path',
        },
        { text: 'Public library', value: 'lib' },
      ],
      publicData: [],
    };
  },
  created() {
    this.main();
  },
  methods: {
    main() {
      this.loading = true;
      axios.get('/api/admin/images').then((response) => {
        const libraries = _.get(response, 'data.library', []);
        libraries.forEach((library) => {
          this.libraryData.push({
            id: library.ID,
            path: library.path,
            email: library.email,
          });
        });
        const queues = _.get(response, 'data.queue', []);
        queues.forEach((queue) => {
          this.queueData.push({
            id: queue.ID,
            path: queue.filename,
            email: queue.email,
          });
        });
        const dropboxes = _.get(response, 'data.dropbox', []);
        dropboxes.forEach((dropbox) => {
          this.dropboxData.push({
            id: dropbox.ID,
            path: dropbox.content,
            email: dropbox.email,
          });
        });
        const pubLibrary = _.get(response, 'data.public', []);
        pubLibrary.forEach((lib) => {
          this.publicData.push({
            path: lib.file,
            lib: lib.lib,
          });
        });
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>

</style>
