<template lang="pug">
  v-dialog(:value="visible" max-width="290" @click:outside="$emit('close')"
    @keydown.esc="$emit('close')")
    v-card.v-application
      v-card-title(class="headline grey lighten-2") Remove items
      v-card-text Do you want to remove the selected items
      v-divider
      v-card-actions
        v-spacer
        v-btn(color="green darken-1"
          text
          @click="$emit('accept')") OK
        v-btn(color="green darken-1"
          text
          @click="$emit('close')") Cancel
</template>

<script>
export default {
  name: 'RemoveAllDialogue',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>

</style>
