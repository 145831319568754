<template lang="pug">
  v-app
    loader(v-if="loader")
    .main-content
      h2 Reconnect Socials/Cloud
      .col-md-8.content_center
        v-simple-table
          template(v-slot:default)
            thead
              tr
                th.text-left
                  | Socials/Cloud
                th.text-left
                  | Token
                th.text-left
                  | Expires at
                th.text-center
                  | Reconnect
            tbody
              tr(v-show="dropbox.connected")
                td
                  img(src="/images/setting/icon-dropbox-blue.png" style="max-width: 36px;")
                  |  Dropbox
                td
                  | {{ dropbox.status }}
                  v-icon(v-if="dropbox.status === 'ok'" color="success") mdi-checkbox-marked-circle
                  v-icon(v-else-if="dropbox.status === 'failed'" color="red") mdi-alert-circle
                td
                  | {{ dropbox.expires_at }}
                td.text-center
                  v-btn.ma-2(outlined color="success" @click="" :href="dropbox.url")
                    | Reconnect
                    v-icon(right dark) mdi-restart
              tr(v-show="facebook.connected")
                td
                  v-avatar(size="36px")
                    img(src="/images/setting/icono-fb.png")
                  |  Facebook
                td
                  | {{ facebook.status }}
                  v-icon(v-if="facebook.status === 'ok'" color="success") mdi-checkbox-marked-circle
                  v-icon(v-else-if="facebook.status === 'failed'" color="red") mdi-alert-circle
                td
                  | {{ facebook.expires_at }}
                td.text-center
                  v-btn.ma-2(outlined color="success" @click="" href="/api/fb/redirect")
                    | Reconnect
                    v-icon(right dark) mdi-restart
              tr(v-show="twitter.connected")
                td
                  v-avatar(size="36px")
                    img(src="/images/setting/circle-twitter-512.png")
                  |  Twitter
                td
                  | {{ twitter.status }}
                  v-icon(v-if="twitter.status === 'ok'" color="success") mdi-checkbox-marked-circle
                  v-icon(v-else-if="twitter.status === 'failed'" color="red") mdi-alert-circle
                td
                  | {{ twitter.expires_at }}
                td.text-center
                  v-btn.ma-2(outlined color="success" @click="" href="/api/twitter-redirect")
                    | Reconnect
                    v-icon(right dark) mdi-restart
              tr(v-show="linkedIn.connected")
                td
                  v-avatar(size="36px")
                    img(src="/images/setting/lnkdin.png")
                  |  LinkedIn
                td
                  | {{ linkedIn.status }}
                  v-icon(v-if="linkedIn.status === 'ok'" color="success") mdi-checkbox-marked-circle
                  v-icon(v-else-if="linkedIn.status === 'failed'" color="red") mdi-alert-circle
                td
                  | {{ linkedIn.expires_at }}
                td.text-center
                  v-btn.ma-2(outlined color="success" @click="" href="/api/linkedin/redirect")
                    | Reconnect
                    v-icon(right dark) mdi-restart

</template>

<script>
import axios from 'axios';
import loader from '../../Loader';

const pending = 'pending...';

export default {
  name: 'Reconnect',

  components: {
    loader,
  },

  data() {
    return {
      loader: false,
      dropbox: {
        status: pending,
        expires_at: '-',
        connected: false,
        url: '',
      },
      facebook: {
        status: pending,
        expires_at: '',
        connected: false,
      },
      twitter: {
        status: pending,
        expires_at: '-',
        connected: false,
      },
      linkedIn: {
        status: pending,
        expires_at: '',
        connected: false,
      },
    };
  },

  mounted() {
    this.getReconnectList();
  },

  methods: {
    reconnectFb() {
      axios.get('/api/debug_token/facebook').then((response) => {
        if (response.data.result) {
          this.facebook.status = response.data.result.status;
          this.facebook.expires_at = response.data.result.expires_human;
        }
      }).catch(() => {
        //
      });
    },
    reconnectTw() {
      axios.get('/api/debug_token/twitter').then((response) => {
        if (response.data.result) {
          this.twitter.status = response.data.result.status;
        }
      }).catch(() => {
        //
      });
    },
    reconnectIn() {
      axios.get('/api/debug_token/linkedin').then((response) => {
        if (response.data.result) {
          this.linkedIn.status = response.data.result.status;
          this.linkedIn.expires_at = response.data.result.expires_human;
        }
      }).catch(() => {
        //
      });
    },
    reconnectDropbox() {
      axios.get('/api/debug_token/dropbox').then((response) => {
        if (response.data.result) {
          this.dropbox.status = response.data.result.status;
        }
      }).catch(() => {
        //
      });
    },
    getReconnectList() {
      axios.get('/api/setting/reconnectList').then((response) => {
        if (response.data.result.dropbox) {
          this.dropbox.connected = true;
          this.dropbox.url = response.data.result.dropbox;
          this.reconnectDropbox();
        }
        if (response.data.result.facebook) {
          this.facebook.connected = true;
          this.reconnectFb();
        }
        if (response.data.result.twitter) {
          this.twitter.connected = true;
          this.reconnectTw();
        }
        if (response.data.result.linkedin) {
          this.linkedIn.connected = true;
          this.reconnectIn();
        }
      }).catch(() => {
        //
      });
    },
  },
};
</script>

<style scoped>

</style>
