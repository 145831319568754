var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("v-text-field", {
        attrs: { color: "success", placeholder: "Enter library name" },
        model: {
          value: _vm.library,
          callback: function($$v) {
            _vm.library = $$v
          },
          expression: "library"
        }
      }),
      _c(
        "v-btn",
        {
          attrs: { color: "success", large: "" },
          nativeOn: {
            click: function($event) {
              return _vm.createLib()
            }
          }
        },
        [
          _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-plus")]),
          _vm._v("Create")
        ],
        1
      ),
      _c(
        "v-list",
        { attrs: { dense: "" } },
        [
          _c(
            "v-list-item-group",
            {
              attrs: { color: "success" },
              model: {
                value: _vm.selectedLibrary,
                callback: function($$v) {
                  _vm.selectedLibrary = $$v
                },
                expression: "selectedLibrary"
              }
            },
            _vm._l(_vm.list, function(library, key) {
              return _c(
                "v-list-item",
                {
                  key: key,
                  on: {
                    click: function($event) {
                      return _vm.setLibrary(library.ID, library.name, key)
                    }
                  }
                },
                [
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: library.limitation,
                          expression: "library.limitation"
                        }
                      ],
                      attrs: { title: "Limitations" }
                    },
                    [
                      _c("v-badge", {
                        attrs: {
                          color: "error",
                          overlap: "",
                          left: "",
                          content: library.limitation
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", {
                        domProps: { textContent: _vm._s(library.name) }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-action",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", small: "" },
                          nativeOn: {
                            click: function($event) {
                              _vm.editVisible = true
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-square-edit-outline")])],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", small: "" },
                          nativeOn: {
                            click: function($event) {
                              _vm.removeVisible = true
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-delete")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c("edit-dialogue", {
        attrs: { visible: _vm.editVisible, label: "Library name:" },
        on: {
          close: function($event) {
            _vm.editVisible = false
          },
          accept: _vm.updateLib
        },
        model: {
          value: _vm.editName,
          callback: function($$v) {
            _vm.editName = $$v
          },
          expression: "editName"
        }
      }),
      _c(
        "v-dialog",
        {
          attrs: { value: _vm.removeVisible, "max-width": "290" },
          on: {
            "click:outside": _vm.closeRemove,
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.closeRemove($event)
            }
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "success" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function($event) {
                          _vm.removeVisible = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                  _c("v-toolbar-title", [_vm._v("Remove library")])
                ],
                1
              ),
              _c("v-card-text", [
                _vm._v("Are you sure you want to delete the following library?")
              ]),
              _vm.editName
                ? _c("v-card-text", [_vm._v(_vm._s(_vm.editName))])
                : _vm._e(),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.removeLib()
                        }
                      }
                    },
                    [_vm._v("OK")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1", text: "" },
                      on: { click: _vm.closeRemove }
                    },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }