var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-container",
        { staticClass: "white-board" },
        [
          _c("h3", [_vm._v("Editing")]),
          _vm._l(_vm.tasks, function(task) {
            return [
              _c("task", {
                attrs: { task: task, libraries: _vm.libraries, edit: true },
                on: { take: _vm.take }
              })
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }