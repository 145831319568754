var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { value: _vm.visible, fullscreen: "" },
          on: {
            "click:outside": _vm.close,
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.close($event)
            }
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "success" } },
                [
                  _c(
                    "v-btn",
                    { attrs: { icon: "", dark: "" }, on: { click: _vm.close } },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                  _c("v-toolbar-title", [_vm._v("Connecting Instagram")])
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c("v-container", { staticClass: "body-2" }, [
                    _c("h2", [_vm._v("Connecting Instagram")]),
                    _c("p", [
                      _vm._v("Connecting Instagram takes an extra few steps.")
                    ]),
                    _c("p", [
                      _vm._v(
                        "The instructions below appear to be a lot, but it really just boils down to   3 simple steps:"
                      )
                    ]),
                    _c("ol", [
                      _c("li", [
                        _vm._v(
                          "Converting your Instagram to professional account"
                        )
                      ]),
                      _c("li", [
                        _vm._v("Connecting the account to your business page")
                      ]),
                      _c("li", [
                        _vm._v("Then refreshing the Evergreen connection")
                      ])
                    ]),
                    _c("h3", [_vm._v("Here's the rundown")]),
                    _c("p", [
                      _vm._v(
                        "You may want to open another browser window with Evergreen to keep this window open  as you follow the steps."
                      )
                    ]),
                    _c("ul", [
                      _c("li", [
                        _vm._v(
                          "Make sure the Instagram account is a professional account:"
                        ),
                        _c("ul", [
                          _c("li", [
                            _vm._v(
                              "In settings, find Account and tap Switch to  Professional Account."
                            )
                          ]),
                          _c("li", [
                            _vm._v(
                              "Pick a category that best describes your business, then select Business."
                            )
                          ]),
                          _c("li", [
                            _vm._v(
                              "You’re all set, you’ve got an Instagram business account. Now complete  your profile using the tips below."
                            )
                          ]),
                          _c("li", [
                            _vm._v("More:  "),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    "https://help.instagram.com/502981923235522"
                                }
                              },
                              [
                                _vm._v(
                                  "https://help.instagram.com/502981923235522"
                                )
                              ]
                            )
                          ])
                        ])
                      ]),
                      _c("li", [
                        _vm._v(
                          "Make sure the Facebook Page has the Instagram account connected to it:"
                        ),
                        _c("ul", [
                          _c("li", [_vm._v("Open your Facebook Page.")]),
                          _c("li", [
                            _vm._v("Select Settings from the left menu.")
                          ]),
                          _c("li", [_vm._v("Select Instagram.")]),
                          _c("li", [
                            _vm._v(
                              'To add an Instagram account to your Page, select Connect Account. Make  sure the "Allow access to Instagram messages in Inbox" is toggled on\n and then click Continue. Enter your Instagram account\'s Username and\n  Password, and select Log in.'
                            ),
                            _c("ul", [
                              _c("li", [
                                _vm._v(
                                  "If you do not have a business account , you will be prompted to convert your personal account."
                                )
                              ])
                            ])
                          ]),
                          _c("li", [
                            _vm._v("More:  "),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    "https://www.facebook.com/business/help/898752960195806"
                                }
                              },
                              [
                                _vm._v(
                                  "https://www.facebook.com/business/help/898752960195806"
                                )
                              ]
                            )
                          ])
                        ])
                      ]),
                      _c("li", [
                        _vm._v(
                          "You may also need to add the Instagram account and Facebook page to Facebook  Business manager and add yourself to the permissions:"
                        ),
                        _c("ul", [
                          _c("li", [
                            _vm._v("Go to"),
                            _c(
                              "a",
                              {
                                attrs: { href: "https://business.facebook.com" }
                              },
                              [_vm._v("  Business Settings.")]
                            )
                          ]),
                          _c("li", [_vm._v("Click Accounts.")]),
                          _c("li", [_vm._v("Click Instagram Accounts.")]),
                          _c("li", [_vm._v("Click the blue Add button.")]),
                          _c("li", [
                            _vm._v("Click Connect Your Instagram Account.")
                          ]),
                          _c("li", [
                            _vm._v(
                              "Enter your Instagram username and password. Note: We may send you a  security code to the email or phone number associated with the account\n  to verify your identity."
                            )
                          ]),
                          _c("li", [_vm._v("Add yourself to the account:")]),
                          _c("ul", [
                            _c("li", [
                              _vm._v("Go to"),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "https://business.facebook.com"
                                  }
                                },
                                [_vm._v("  Business Settings.")]
                              )
                            ]),
                            _c("li", [_vm._v("Click Accounts.")]),
                            _c("li", [_vm._v("Click Instagram Accounts.")]),
                            _c("li", [_vm._v("Click Add People.")]),
                            _c("li", [
                              _vm._v(
                                "Select a person from the left-hand column. Then toggle on the tasks  you want to grant permissions for. A blue toggle means it’s on,\n  gray means it’s off"
                              )
                            ]),
                            _c("li", [_vm._v("Click Assign.")])
                          ]),
                          _c("li", [
                            _vm._v("More:  "),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    "https://www.facebook.com/business/help/1125825714110549?id=420299598837059"
                                }
                              },
                              [
                                _vm._v(
                                  "https://www.facebook.com/business/help/1125825714110549?id=420299598837059"
                                )
                              ]
                            )
                          ])
                        ])
                      ]),
                      _c("li", [
                        _vm._v(
                          "After that, you may need to refresh Evergreen's connection to Facebook to   retrieve the new Instagram access:"
                        ),
                        _c("ul", [
                          _c("li", [
                            _vm._v(
                              'On the Dashboard page, scroll to the bottom and click "check permissions"(it may redirect you to Facebook and accept all the prompts)'
                            )
                          ]),
                          _c("li", [
                            _vm._v(
                              "After that, click Settings in the main Evergreen navigation"
                            )
                          ]),
                          _c("li", [_vm._v("Click on the Instagram icon")]),
                          _c("li", [_vm._v("Click on the Refresh button")]),
                          _c("li", [
                            _vm._v(
                              "Finally, click on the dropdown menu and you should see your Instagram  account, then click Add Now"
                            )
                          ])
                        ])
                      ])
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }