<template lang="pug">
  v-dialog(:value="visible" @click:outside="close" @keydown.esc="close" max-width="80%")
    v-card
      v-toolbar(color="success")
        v-btn(icon dark @click="close")
          v-icon mdi-close
        v-toolbar-title Pause to X hours
      v-card-text
        v-container
          v-text-field(v-model="hours" type="number" required label="Hours:" min="1").default
      v-card-actions
        v-spacer
        v-btn(color="success" @click="pause" class="mx-2" text) YES, SAVE
        v-btn(color="error" @click="close" class="mx-2" text) NO, CANCEL
</template>

<script>
import axios from 'axios';
import _ from 'lodash';

export default {
  name: 'Pause',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    shid: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      hours: 1,
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    pause() {
      const data = { SHID: this.shid, hours: this.hours };
      axios.post('/api/setting/paused_hours', data).then((response) => {
        const date = _.get(response, 'data.date', '');
        this.$emit('accept', date);
      });
    },
  },
};
</script>

<style scoped>

</style>
<style>
  .v-text-field__slot input {
    background: none;
  }
</style>
