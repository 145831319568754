<template lang="pug">
  v-col(xl="2" lg="3" md="4" sm="6")
    preview-image(
      :visible="visible"
      :image="image.image"
      :thumb="image.thumb"
      @close="visible=false"
    )

    remove-dialogue(
      :visible="removeDialogue"
      :item="image.caption"
      @close="removeDialogue=false"
      @accept="deleteImage"
    )

    edit-dialogue(
      :visible="editVisible"
      label="Photo Caption:"
      v-model:text="text"
      :copyChanges="copyChanges"
      :checkbox="true"
      @close="editVisible = false"
      @accept="saveEdit"
      @copy="copyChangesQueue"
    )

    top-dialogue(
      :visible="topVisible"
      :id="String(image.ID)"
      @close="topVisible = false"
    )
    limitation-instagram(
      :data="limitation_instagram"
      :visible="instagram_visible"
      @close="instagram_visible = false"
    )
    limitation-twitter(:visible="twLimitationVisible" @close="twLimitationVisible = false")

    .gallery-thumb
      a.thumb-img(@click="visible=true")
        .marked_block
          span(:class="markedClass") published
          div(
            v-if="isLongTw(image.text)"
            title="The text content of a Tweet can contain up to 280 characters or Unicode glyphs"+
              "! Some glyphs will count as more than one character. Make the text shorter!"
            )
            v-badge(color="error" :content="makeLimit(image.text.length)" overlap)
              v-avatar(size="30")
                v-img(src="/images/setting/circle-twitter-60.png")
          div(
            v-if="image.limitation && limitedTw(image)"
            @click.stop="twLimitationVisible = true"
          )
            v-badge(color="error" icon="mdi-cancel" overlap)
              v-avatar(size="30")
                v-img(src="/images/setting/circle-twitter-60.png")
          div(
            v-if="image.limitation && limitedInsta(image)"
            @click.stop="instagram_visible = true,limitation_instagram = image.limitation"
          )
            v-badge(color="error" icon="mdi-cancel" overlap)
              v-avatar(size="30")
                v-img(src="/images/setting/instagram-60.jpeg")
        v-img(:src="image.thumb" max-height="92").img-responsive

      p.card_text {{image.short}}

      .col-xs-7.px-0
        input(:class="imSetClass" type="checkbox" v-model="image.checkbox"
          @click="setCheckbox(String(image.ID))")
        v-tooltip(top)
          template(v-slot:activator="{ on }")
            span(v-on="on").my_badge
              i.fas.fa-eye.color_eye.pl(:class="eyeColor")
              span.pl {{image.formatEverScore}}
          span
            | everScore {{image.everScore}}
            br
            | likes {{image.likes}}
            br
            | reactions {{image.reactions}}
            br
            | comments {{image.comments}}
            br
            | shares {{image.shares}}
            br
            | views {{image.views}}
            br
            | engagements {{image.engagements}}

      div.col-xs-5.text-right.action.px-0
        a(
          @click="topVisible=true"
          title="This adds a copy of this post to the top of your queue"
        ).to_queue.pr ⏫
        a(@click="editVisible=true").pr
          i.fas.fa-edit
        a(@click="removeDialogue=true")
          i(style="color: #e64b3e").fas.fa-trash-alt
</template>

<script>
import axios from 'axios';
import PreviewImage from '../common/PreviewImage';
import RemoveDialogue from '../common/RemoveDialogue';
import EditDialogue from '../common/EditDialogue';
import TopDialogue from '../common/TopDialogue';
import LimitationInstagram from '../common/LimitationInstagram';
import limitationTwitter from './LimitationTwitter';

export default {
  name: 'ImageComponent',

  components: {
    RemoveDialogue,
    PreviewImage,
    EditDialogue,
    TopDialogue,
    LimitationInstagram,
    'limitation-twitter': limitationTwitter,
  },

  props: {
    image: {
      type: Object,
      default: () => {},
    },
    twitter: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      visible: false,
      removeDialogue: false,
      editVisible: false,
      topVisible: false,
      instagram_visible: false,
      limitation_instagram: null,
      twLimitationVisible: false,
      text: '',
      copyChanges: false,
      selected: '',
    };
  },

  computed: {
    markedClass() {
      return this.image.marked ? 'marked ' : 'marked hide';
    },

    imSetClass() {
      return this.image.marked ? 'published im_set' : 'im_set';
    },

    eyeColor() {
      if (this.image.background === 'green') {
        return 'badge_green';
      }

      if (this.image.background === 'yellow') {
        return 'badge_yellow';
      }

      return 'badge_red';
    },
  },

  mounted() {
    this.text = this.image.text;
  },

  methods: {
    deleteImage() {
      axios.delete('/api/image', { data: { image_id: this.image.ID } }).then(() => {
        this.$emit('updateGallery');
      });

      this.removeDialogue = false;
    },

    saveEdit() {
      axios.post('/api/image/edit-caption', {
        ID: this.image.ID,
        caption: this.text,
        copy: this.copyChanges,
      }).then(() => {
        this.$emit('updateGallery');
      });

      this.editVisible = false;
    },
    copyChangesQueue() {
      this.copyChanges = !this.copyChanges;
    },
    toTop() {
      axios.post('/api/image/to-queue', { ID: this.image.ID, scheduler_id: 0 }).then(() => {
        this.$emit('updateGallery');
      });
      this.topVisible = false;
    },
    setCheckbox(id) {
      this.$store.dispatch('setCheckbox', id);
    },
    isLongTw(text) {
      if (text.length > 280 && this.twitter) {
        return true;
      }
      return false;
    },
    makeLimit(count) {
      return `${count}/280`;
    },
    limitedTw(image) {
      return image.limitation.type === 'twitter' && image.limitation.limited;
    },
    limitedInsta(image) {
      return image.limitation.type === 'instagram' && image.limitation.limited;
    },
  },
};
</script>

<style scoped>
  .im_set {
    -webkit-appearance: checkbox;
    margin-top: 0;
  }

  .pl {
    padding-left: 5px;
  }

  .pr {
    padding-right: 5px;
  }
  .marked_block {
    position: absolute;
    top: 0px;
    left: 6px;
    color: #e64c3c;
    font: 10px/20px neris-black,"Helvetica Neue Black","Helvetica Neue",Helvetica,Arial,sans-serif;
    z-index: 10;
    text-transform: uppercase;
    letter-spacing: .02em;
  }
  .marked {
    display: block;
    background-color: white;
    padding: 2px 4px;
    border-radius: 6px;
    z-index: 9;
  }
  .v-tooltip__content{
    margin-top:  -105px !important;
    margin-left: -75px !important;
  }
  .row {
    margin: auto !important;
  }
</style>
<style>
  .card_text {
    overflow: hidden;
    color: black;
    font-size: 16px !important;
    text-decoration: none;
    height: 40px;
    line-height: 1.4;
  }
  p.card_text {
    margin: 0 !important;
  }

  .badge_green{
    color: #338e4c !important;
  }
  .badge_yellow{
    color: darkorange !important;
  }
  .badge_red{
    color: #d13322 !important;
  }

  .my_badge{
    cursor: pointer;
    text-decoration: underline;
  }

</style>
