import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './components/App.vue';
import queue from './components/queue';
import reconnect from './components/settings/reconnect';

Vue.use(VueRouter);

export default [
  {
    path: '/',
    name: 'app',
    component: App,
    meta: { crumb: 'Evergreen' },
    children: [
      {
        path: 'queue/:id',
        component: queue,
        meta: { crumb: 'Queue' },
      },
      {
        path: '/settings/reconnect',
        component: reconnect,
        meta: { crumb: 'Reconnect Socials/Cloud' },
      },
    ],
  },
];
