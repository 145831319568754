var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.visible, width: "600" },
      on: {
        "click:outside": _vm.close,
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.close($event)
        }
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { color: "success" } },
            [
              _c(
                "v-btn",
                { attrs: { icon: "", dark: "" }, on: { click: _vm.close } },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", [_vm._v("Instagram limitations")])
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("v-simple-table", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function() {
                      return [
                        _c("thead", [
                          _c("tr", [
                            _c("th"),
                            _c("th"),
                            _c("th", [_vm._v("Supported")]),
                            _c("th")
                          ])
                        ]),
                        _c(
                          "tbody",
                          [
                            _c("tr", [
                              _c("td", [_vm._v("Will publish")]),
                              _c("td"),
                              _c("td"),
                              _c(
                                "td",
                                [
                                  _c("v-icon", { attrs: { color: "red" } }, [
                                    _vm._v("mdi-close-circle")
                                  ])
                                ],
                                1
                              )
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("Format")]),
                              _c("td", [_vm._v(_vm._s(_vm.data.format))]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.data.formats_limit))
                              ]),
                              _c(
                                "td",
                                [
                                  _vm.data.format_status
                                    ? _c(
                                        "v-icon",
                                        { attrs: { color: "success" } },
                                        [_vm._v("mdi-checkbox-marked-circle")]
                                      )
                                    : _c(
                                        "v-icon",
                                        { attrs: { color: "red" } },
                                        [_vm._v("mdi-close-circle")]
                                      )
                                ],
                                1
                              )
                            ]),
                            _vm.data.video
                              ? [
                                  _c("tr", [
                                    _c("td", [_vm._v("Video duration")]),
                                    _c("td", [
                                      _vm._v(_vm._s(_vm.data.duration) + " s")
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(_vm.data.duration_limit) + " s"
                                      )
                                    ]),
                                    _c(
                                      "td",
                                      [
                                        _vm.data.duration_status
                                          ? _c(
                                              "v-icon",
                                              { attrs: { color: "success" } },
                                              [
                                                _vm._v(
                                                  "mdi-checkbox-marked-circle"
                                                )
                                              ]
                                            )
                                          : _c(
                                              "v-icon",
                                              { attrs: { color: "red" } },
                                              [_vm._v("mdi-close-circle")]
                                            )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("tr", [
                                    _c("td", [_vm._v("Frame rate:")]),
                                    _c("td", [
                                      _vm._v(_vm._s(_vm.data.fps) + " FPS")
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(_vm.data.fps_limit) + " FPS"
                                      )
                                    ]),
                                    _c(
                                      "td",
                                      [
                                        _vm.data.fps_status
                                          ? _c(
                                              "v-icon",
                                              { attrs: { color: "success" } },
                                              [
                                                _vm._v(
                                                  "mdi-checkbox-marked-circle"
                                                )
                                              ]
                                            )
                                          : _c(
                                              "v-icon",
                                              { attrs: { color: "red" } },
                                              [_vm._v("mdi-close-circle")]
                                            )
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              : [
                                  _c("tr", [
                                    _c("td", [
                                      _vm._v("Supported aspect ratio:")
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        "Must be within a 4:5 to 1.91:1 range"
                                      )
                                    ]),
                                    _c("td")
                                  ])
                                ],
                            _c("tr", [
                              _c("td", [_vm._v("Dimension")]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.data.dimension) + " px")
                              ]),
                              _c("td"),
                              _c("td")
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("Size")]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.data.size_mb) + " MB")
                              ]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.data.size_limit) + " MB max")
                              ]),
                              _c(
                                "td",
                                [
                                  _vm.data.size_status
                                    ? _c(
                                        "v-icon",
                                        { attrs: { color: "success" } },
                                        [_vm._v("mdi-checkbox-marked-circle")]
                                      )
                                    : _c(
                                        "v-icon",
                                        { attrs: { color: "red" } },
                                        [_vm._v("mdi-close-circle")]
                                      )
                                ],
                                1
                              )
                            ]),
                            _c("tr", [
                              _c("td", [_vm._v("Aspect ratio:")]),
                              _c("td", [_vm._v(_vm._s(_vm.data.a_ratio))]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.data.a_ratio_limit))
                              ]),
                              _c(
                                "td",
                                [
                                  _vm.data.a_ratio_status
                                    ? _c(
                                        "v-icon",
                                        { attrs: { color: "success" } },
                                        [_vm._v("mdi-checkbox-marked-circle")]
                                      )
                                    : _c(
                                        "v-icon",
                                        { attrs: { color: "red" } },
                                        [_vm._v("mdi-close-circle")]
                                      )
                                ],
                                1
                              )
                            ])
                          ],
                          2
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c("v-icon", { attrs: { color: "info" } }, [
                            _vm._v("mdi-information")
                          ])
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { cols: "10" } }, [
                        _c("p", [
                          _vm._v(
                            "Solution: Delete item. Upload a supported new file without current limitation!"
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-card-actions")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }