var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.visible,
        width: "100%",
        fullscreen: "",
        "hide-overlay": ""
      },
      on: {
        "click:outside": _vm.closeDialog,
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeDialog($event)
        }
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { color: "success" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: { click: _vm.closeDialog }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", [_vm._v("Download from site")])
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "wide_button",
                              attrs: {
                                color: "success",
                                small: "",
                                loading: _vm.loading
                              },
                              on: { click: _vm.getPublic }
                            },
                            [
                              _c("v-icon", { attrs: { "x-small": "" } }, [
                                _vm._v("mdi-content-copy")
                              ]),
                              _vm._v("Copy all images to library")
                            ],
                            1
                          ),
                          _c(
                            "v-list",
                            { attrs: { dense: "" } },
                            [
                              _c("v-subheader", [_vm._v("Public libraries")]),
                              _c(
                                "v-list-item-group",
                                {
                                  attrs: { color: "success" },
                                  model: {
                                    value: _vm.selectedItem,
                                    callback: function($$v) {
                                      _vm.selectedItem = $$v
                                    },
                                    expression: "selectedItem"
                                  }
                                },
                                _vm._l(_vm.galleries, function(gallery, key) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: key,
                                      on: {
                                        click: function($event) {
                                          return _vm.setItem(key)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", {
                                            domProps: {
                                              textContent: _vm._s(gallery)
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "9" } },
                        [
                          _c("alert-message", { ref: "alertComponent" }),
                          _c(
                            "v-container",
                            [
                              _c(
                                "v-row",
                                _vm._l(_vm.images, function(image, key) {
                                  return _c(
                                    "v-col",
                                    { key: key, attrs: { cols: "4" } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "gallery-thumb" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "thumb-img",
                                              on: {
                                                click: function($event) {
                                                  _vm.visible = true
                                                }
                                              }
                                            },
                                            [
                                              _c("v-img", {
                                                staticClass: "img-responsive",
                                                attrs: { src: image.thumb }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "thumb-text" },
                                            [
                                              _c("p", [
                                                _vm._v(_vm._s(image.short))
                                              ]),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "success",
                                                    small: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.getImage(
                                                        image.file,
                                                        image.title,
                                                        key
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [_vm._v("mdi-content-copy")]
                                                  ),
                                                  _vm._v("copy to library")
                                                ],
                                                1
                                              ),
                                              image.coped
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "pl-1",
                                                      attrs: {
                                                        small: "",
                                                        color: "success"
                                                      }
                                                    },
                                                    [_vm._v("mdi-check")]
                                                  )
                                                : _vm._e(),
                                              image.copedNo
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "pl-1",
                                                      attrs: {
                                                        small: "",
                                                        color: "error"
                                                      }
                                                    },
                                                    [_vm._v("mdi-cancel")]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }