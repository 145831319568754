<template lang="pug" class="v-application">
  v-row(justify="center")
    v-dialog(:value="visible" @click:outside="close"
      @keydown.esc="close" fullscreen)
      v-card
        v-toolbar(color="success")
          v-btn(icon dark @click="close")
            v-icon mdi-close
          v-toolbar-title Connecting Instagram
        v-card-text
          v-container.body-2
            h2 Connecting Instagram
            p Connecting Instagram takes an extra few steps.
            p The instructions below appear to be a lot, but it really just boils down to
              | &nbsp;  3 simple steps:
            ol
              li Converting your Instagram to professional account
              li Connecting the account to your business page
              li Then refreshing the Evergreen connection
            h3 Here's the rundown
            p You may want to open another browser window with Evergreen to keep this window open
             | &nbsp; as you follow the steps.
            ul
              li Make sure the Instagram account is a professional account:
                ul
                  li In settings, find Account and tap Switch to
                    | &nbsp; Professional Account.
                  li Pick a category that best describes your business, then select Business.
                  li You’re all set, you’ve got an Instagram business account. Now complete
                    | &nbsp; your profile using the tips below.
                  li More: &nbsp;
                    a(href="https://help.instagram.com/502981923235522")
                      | https://help.instagram.com/502981923235522
              li Make sure the Facebook Page has the Instagram account connected to it:
                ul
                  li Open your Facebook Page.
                  li Select Settings from the left menu.
                  li Select Instagram.
                  li To add an Instagram account to your Page, select Connect Account. Make
                    | &nbsp; sure the "Allow access to Instagram messages in Inbox" is toggled on
                    | &nbsp;and then click Continue. Enter your Instagram account's Username and
                    | &nbsp; Password, and select Log in.
                    ul
                      li If you do not have a business account
                        |&nbsp;, you will be prompted to convert your personal account.
                  li More: &nbsp;
                    a(href="https://www.facebook.com/business/help/898752960195806")
                      | https://www.facebook.com/business/help/898752960195806
              li You may also need to add the Instagram account and Facebook page to Facebook
                | &nbsp; Business manager and add yourself to the permissions:
                ul
                  li Go to
                    a(href="https://business.facebook.com")
                      | &nbsp; Business Settings.
                  li Click Accounts.
                  li Click Instagram Accounts.
                  li Click the blue Add button.
                  li Click Connect Your Instagram Account.
                  li Enter your Instagram username and password. Note: We may send you a
                    | &nbsp; security code to the email or phone number associated with the account
                    | &nbsp; to verify your identity.
                  li Add yourself to the account:
                  ul
                    li Go to
                      a(href="https://business.facebook.com")
                        | &nbsp; Business Settings.
                    li Click Accounts.
                    li Click Instagram Accounts.
                    li Click Add People.
                    li Select a person from the left-hand column. Then toggle on the tasks
                      | &nbsp; you want to grant permissions for. A blue toggle means it’s on,
                      | &nbsp; gray means it’s off
                    li Click Assign.
                  li More: &nbsp;
                    a(href="https://www.facebook.com/business/help/1125825714110549?id=420299598837059")
                      | https://www.facebook.com/business/help/1125825714110549?id=420299598837059
                li After that, you may need to refresh Evergreen's connection to Facebook to
                  | &nbsp;  retrieve the new Instagram access:
                  ul
                    li On the Dashboard page, scroll to the bottom and click "check permissions"
                      | (it may redirect you to Facebook and accept all the prompts)
                    li After that, click Settings in the main Evergreen navigation
                    li Click on the Instagram icon
                    li Click on the Refresh button
                    li Finally, click on the dropdown menu and you should see your Instagram
                      | &nbsp; account, then click Add Now
</template>

<script>

export default {
  name: 'InstagramDialogue',

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
<style scoped>
ul{
  list-style: circle;
}
ol{
  list-style: decimal;
}
</style>
