/*  eslint no-param-reassign: off */
/*  eslint no-unused-vars: off */

import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'lodash';
import axios from 'axios';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    queue: [],
    images: [],
    publicGalleries: [],
    myLibraries: [],
    schedulers: {},
  },

  modules: {},

  mutations: {
    setPublicGalleries: (state, payload) => {
      state.publicGalleries = payload;
    },
    setQueue: (state, payload) => {
      state.queue = payload;
    },
    setSchedulers: (state, payload) => {
      state.schedulers = payload;
    },
    setMyLibraries: (state, payload) => {
      state.myLibraries = payload;
    },
    pushMyLibraries: (state, payload) => {
      state.myLibraries.push(payload);
    },
    editLibrary: (state, payload) => {
      const index = state.myLibraries.map((item) => item.ID).indexOf(payload.ID);
      state.myLibraries[index].name = payload.name;
    },
    removeLibrary: (state, id) => {
      const index = state.myLibraries.map((item) => item.ID).indexOf(id);
      state.myLibraries.splice(index, 1);
    },
    removeQueue: (state, id) => {
      const index = _.findIndex(state.queue, (item) => item.ID === id);
      state.queue.splice(index, 1);
    },
    editQueue: (state, payload) => {
      const index = _.findIndex(state.queue, (item) => item.ID === payload.id);
      state.queue[index].text = payload.text;
    },
    setImages: (state, images) => {
      images.forEach((value, index) => {
        images[index].checkbox = false;
      });
      state.images = images;
    },
    setCheckbox: (state, index) => {
      state.images[index].checkbox = !state.images[index].checkbox;
    },
    setAllPublished: (state) => {
      state.images.forEach((value, index) => {
        if (state.images[index].marked) {
          state.images[index].checkbox = true;
        }
      });
    },
    setAll: (state, checkbox) => {
      state.images.forEach((value, index) => {
        state.images[index].checkbox = checkbox;
      });
    },
  },

  actions: {
    removeQueue: ({ commit }, id) => {
      commit('removeQueue', id);
    },
    editQueue: ({ commit }, payload) => {
      commit('editQueue', payload);
    },
    setImages: ({ commit }, payload) => {
      commit('setImages', _.get(payload, 'data.images', []));
    },
    setCheckbox: ({ commit, state }, payload) => {
      const index = _.findIndex(state.images, (item) => String(item.ID) === payload);
      commit('setCheckbox', index);
    },
    setAllPublished: ({ commit }) => {
      commit('setAllPublished');
    },
    setAll: ({ commit, state }, payload) => {
      commit('setAll', payload);
    },
    getData(context) {
      return new Promise((resolve) => {
        axios.get('/api/myImages/data-libraries')
          .then((response) => {
            const galleries = _.get(response, 'data.galleries', []);
            const myLibraries = _.get(response, 'data.myLibraries', []);
            const schedulers = _.get(response, 'data.schedulers', []);
            context.commit('setPublicGalleries', galleries);
            context.commit('setSchedulers', schedulers);
            context.commit('setMyLibraries', myLibraries);
          })
          .then(() => {
            resolve();
          });
      });
    },
  },
  getters: {
    checkedImages: (state) => {
      const selected = [];
      state.images.forEach((image) => {
        if (image.checkbox === true) {
          selected.push(image.ID);
        }
      });
      return selected;
    },
  },
});

export default store;
