<template lang="pug" id="root">

</template>

<script>
export default {
  name: 'Main',
  methods: {
    sourceIcon(source) {
      let icon = '';
      if (source.cloud_folder !== '0') {
        icon = 'mdi-dropbox';
      } else if (source.gallery_name) {
        icon = 'mdi-image-multiple';
      } else if (source.wordpress) {
        icon = 'mdi-wordpress';
      }
      return icon;
    },
    sourceType(source) {
      let name = '';
      if (source.cloud_folder !== '0') {
        name = 'Dropbox';
      } else if (source.gallery_name) {
        name = 'Library';
      } else if (source.wordpress) {
        name = 'Wordpress';
      }
      return name;
    },
    sourceName(source) {
      let name = '';
      if (source.cloud_folder !== '0') {
        name = source.cloud_folder;
      } else if (source.gallery_name) {
        name = source.gallery_name;
      } else if (source.wordpress) {
        name = source.wordpress === 1 ? 'will generate' : source.wordpress;
      }
      return name;
    },
  },
};
</script>

<style scoped>

</style>
