<template lang="pug">
  v-dialog(:value="visible" @click:outside="$emit('close')" @keydown.esc="$emit('close')"
    max-width="80vh" max-height="80vh"
    )
    v-img(contain :src="image" :lazy-src="thumb")
</template>

<script>
export default {
  name: 'PreviewImage',
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    image: {
      type: String,
      default: '',
    },
    thumb: {
      type: String,
      default: '',
    },
  },
  methods: {

  },
};
</script>

<style scoped>

</style>
