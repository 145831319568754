var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "white-board" },
    [
      _c(
        "v-row",
        { staticClass: "b-white" },
        [
          _c(
            "v-col",
            { staticClass: "pa-0", attrs: { cols: "2" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-alert",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.taskData.paused,
                          expression: "taskData.paused"
                        }
                      ],
                      staticClass: "mb-0",
                      attrs: {
                        type: "error",
                        color: "warning",
                        border: "right",
                        text: "",
                        dense: "",
                        title:
                          "This task is paused. " +
                          _vm.taskData.paused +
                          " failed attempts.Reason: " +
                          _vm.taskData.info
                      }
                    },
                    [_vm._v("Failed...")]
                  ),
                  _c(
                    "v-card-text",
                    {
                      staticClass:
                        "d-flex align-center justify-center social_image"
                    },
                    [
                      _c("img", {
                        staticStyle: { "max-height": "100px" },
                        attrs: {
                          src: _vm.taskData.sn_image,
                          title: _vm.taskData.page_name
                        }
                      })
                    ]
                  ),
                  _c("v-card-title", { staticClass: "subtitle-2" }, [
                    _c("div", {
                      staticClass: "fab",
                      class: _vm.taskData.social_ico
                    }),
                    _c(
                      "a",
                      {
                        staticClass: "link",
                        attrs: { href: _vm.taskData.sn_link, target: "_blank" }
                      },
                      [
                        _vm._v("  " + _vm._s(_vm.taskData.page_name)),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.taskData.type === "group",
                                expression: "taskData.type === 'group'"
                              }
                            ]
                          },
                          [_vm._v("(group)")]
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pl-10", attrs: { cols: "10" } },
            [
              _c(
                "v-container",
                { staticClass: "pl-0" },
                [
                  _c("alert-message", { ref: "alertComponent" }),
                  _c("p", [_vm._v("Select Content:")]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-5",
                      attrs: { large: "", color: "grey" },
                      nativeOn: {
                        click: function($event) {
                          _vm.showDropbox = true
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { color: "black" } }, [
                        _vm._v("mdi-dropbox")
                      ]),
                      _vm._v("DROPBOX")
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-5",
                      attrs: { large: "", color: "grey" },
                      nativeOn: {
                        click: function($event) {
                          _vm.showLibrary = true
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { color: "black" } }, [
                        _vm._v("mdi-image-multiple")
                      ]),
                      _vm._v("LIBRARY")
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-5",
                      attrs: { large: "", color: "grey" },
                      nativeOn: {
                        click: function($event) {
                          _vm.showWordpress = true
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { color: "black" } }, [
                        _vm._v("mdi-wordpress")
                      ]),
                      _vm._v("WORDPRESS")
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-container",
                { staticClass: "pl-0" },
                [
                  _c("span", [_vm._v("Publishing Mix:")]),
                  _c(
                    "v-list",
                    [
                      _vm._l(_vm.taskData.sources, function(source, sourceKey) {
                        return [
                          _c("v-divider", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: sourceKey !== 0,
                                expression: "sourceKey !== 0"
                              }
                            ],
                            staticClass: "ma-0"
                          }),
                          _c(
                            "v-list-item",
                            {
                              staticStyle: { "min-height": "auto !important" }
                            },
                            [
                              _c(
                                "v-list-item-avatar",
                                {
                                  staticClass: "lite source",
                                  attrs: { color: "grey" }
                                },
                                [
                                  _c("v-icon", { attrs: { color: "black" } }, [
                                    _vm._v(_vm._s(_vm.sourceIcon(source)))
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                { staticClass: "pa-1" },
                                [
                                  _c("v-list-item-title", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.sourceType(source) +
                                          " : " +
                                          _vm.sourceName(source)
                                      )
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-action",
                                [
                                  _c("v-text-field", {
                                    staticStyle: { "max-width": "80px" },
                                    attrs: {
                                      type: "number",
                                      value: source.percent,
                                      max: "100",
                                      min: "0",
                                      solo: ""
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.setPercent(sourceKey, $event)
                                      }
                                    }
                                  }),
                                  _c("p", [_vm._v("%")]),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "", color: "error" },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.removeSource(sourceKey)
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-delete")])],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("span", [_vm._v("Select Frequency:")]),
                  _c("v-select", {
                    attrs: {
                      items: _vm.plans,
                      "item-text": "value",
                      "item-value": "key",
                      solo: ""
                    },
                    model: {
                      value: _vm.taskData.time,
                      callback: function($$v) {
                        _vm.$set(_vm.taskData, "time", $$v)
                      },
                      expression: "taskData.time"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("span", [_vm._v("Recycle Posts?")]),
                      _c(
                        "v-radio-group",
                        {
                          attrs: { value: _vm.taskData.do_not_delete, row: "" },
                          on: {
                            change: function($event) {
                              return _vm.setPublish($event)
                            }
                          }
                        },
                        [
                          _c("v-radio", {
                            attrs: { color: "success", label: "Yes", value: 1 }
                          }),
                          _c("v-radio", {
                            attrs: { color: "success", label: "No", value: 0 }
                          }),
                          _c("v-radio", {
                            attrs: {
                              color: "success",
                              label: "Custom",
                              value: _vm.def.CUSTOM
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.taskData.do_not_delete === _vm.def.CUSTOM
                    ? _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          _c("span", { staticClass: "text-success" }, [
                            _vm._v("Times:  ")
                          ]),
                          _c("v-text-field", {
                            staticClass: "d-inline-block",
                            staticStyle: { "max-width": "80px" },
                            attrs: {
                              type: "number",
                              value: _vm.taskData.posted_count,
                              min: "0",
                              solo: ""
                            },
                            on: {
                              input: function($event) {
                                return _vm.setCount($event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "justify-center d-flex" },
        [
          _c(
            "v-btn",
            {
              staticClass: "mx-1",
              attrs: { large: "", color: "success" },
              on: {
                click: function($event) {
                  return _vm.save(_vm.taskData.ID)
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-content-save")]), _vm._v("Save")],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "mx-1",
              attrs: { large: "", color: "error" },
              on: {
                click: function($event) {
                  return _vm.remove(_vm.taskData.ID)
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-delete")]), _vm._v("Delete")],
            1
          )
        ],
        1
      ),
      _c("wordpress-dialog", {
        attrs: { visible: _vm.showWordpress },
        on: {
          submit: _vm.submitWordpress,
          close: function($event) {
            _vm.showWordpress = false
          }
        }
      }),
      _c("library-dialog", {
        attrs: { visible: _vm.showLibrary, libraries: _vm.libraries },
        on: {
          submit: _vm.submitLibrary,
          close: function($event) {
            _vm.showLibrary = false
          }
        }
      }),
      _c("dropbox", {
        attrs: { visible: _vm.showDropbox },
        on: {
          submit: _vm.submitDropbox,
          close: function($event) {
            _vm.showDropbox = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }