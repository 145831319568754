<template lang="pug">
  v-bottom-navigation(color="success" :value="active")
    v-btn(href='/api/admin/wrong-schedulers') Wrong Schedulers
    v-btn(href='/api/admin/no-images') No images
</template>

<script>
export default {
  name: 'NavBar',
  props: {
    active: {
      type: Number,
      default: 0,
    },
  },
};
</script>
