<template lang="pug">
  v-dialog(:value="visible" max-width="50%" @click:outside="close" @keydown.esc="close")
    v-card
      v-toolbar(color="success")
        v-btn(icon dark @click="close")
          v-icon mdi-close
        v-toolbar-title Select Wordpress
      v-card-title.headline Connect to wordpress
      v-card-actions
        v-spacer
        v-btn(color="green darken-1" @click="submit" text) Continue

</template>

<script>
export default {
  name: 'WordpressDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.$emit(('submit'));
    },
  },
};
</script>

<style scoped>

</style>
