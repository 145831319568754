<template lang="pug">
    div.col-md-12
      remove-all-dialogue(
        :visible="removeAllDialogue"
        @close="removeAllDialogue=false"
        @accept="deleteSelected"
      )
      uploads(
        :visible="uploadsDialog"
        :libraryId="String(galleryId)"
        @close="uploadsClosed"
      )
      uploads-public(
        :visible="uploadsPublicDialog"
        :galleries="publicGalleries"
        :libraryId="String(galleryId)"
        @close="uploadsPublicClosed"
      )
      copy-dialog(
        :visible="copyDialogue"
        @close="copyDialogue=false"

      )
      v-row
        ul
          li
            input(
              id="all"
              type="checkbox"
              v-model="all"
              @click="setAll"
            ).im_set
            label(for="all") &nbsp;All
          li
            input(
              id="all_published"
              type="checkbox"
              v-model="allPublished"
              @click="setPublished"
              ).im_set
            label(for="all_published") &nbsp;All published
        v-spacer
        v-btn(color="error" outlined @click="removeAllDialogue=true").images_menu
          v-icon(left) mdi-delete
          | Delete
        v-btn(color="success" outlined @click="unmarkImages").images_menu
          v-icon(left) mdi-reply
          | Unmark
        v-btn(color="success" outlined @click="copyDialogue=true").images_menu
          v-icon(left) mdi-content-copy
          | Copy
        v-btn(color="success" @click="uploadsPublicDialog=true").images_menu
          v-icon(left) mdi-cloud-download
          | Get Images from Evergreen
        v-btn(
          id="uploads"
          color="success"
          data-toggle="modal"
          @click="uploadsDialog=true"
          ).images_menu
          v-icon(left) mdi-cloud-upload
          | Upload
      v-row
        v-progress-linear(
          v-show="loading"
          color="warning"
          indeterminate
          rounded
          height="6")
        image-component(
          v-for="image in images"
          :image="image"
          :twitter="twitter"
          :key="image.ID"
          @updateGallery="updateGallery"
        )
        v-row(v-show="!images.length" align="center" justify="space-around").mt-5
          p.white-board This library is currently empty.
            a(@click="uploadsDialog=true") &nbsp;Add some images.

</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import imageComponent from './ImageComponent';
import RemoveAllDialogue from '../common/RemoveAllDialogue';
import uploads from './Uploads';
import uploadsPublic from './UploadsPublic';
import copyDialog from './CopyDialogue';

export default {
  name: 'Images',

  components: {
    imageComponent,
    'remove-all-dialogue': RemoveAllDialogue,
    uploads,
    'uploads-public': uploadsPublic,
    'copy-dialog': copyDialog,
  },

  data() {
    return {
      loading: false,
      twitter: false,
      galleryId: 0,
      allPublished: false,
      copyDialogue: false,
      removeAllDialogue: false,
      uploadsDialog: false,
      uploadsPublicDialog: false,
      all: false,
    };
  },
  computed: {
    images() {
      return this.$store.state.images;
    },
    publicGalleries() {
      return this.$store.state.publicGalleries;
    },
    getSelected() {
      return this.$store.getters.checkedImages;
    },
  },
  watch: {
    galleryId() {
      this.updateGallery();
    },
  },
  created() {
    this.$store.dispatch('getData');
  },

  methods: {
    updateGallery() {
      if (this.galleryId) {
        this.loading = true;
        axios.get(`/api/gallery/${this.galleryId}`).then((response) => {
          this.$store.dispatch('setImages', response);
          this.twitter = _.get(response, 'data.twitter', false);
        }).finally(() => {
          this.loading = false;
        });
      }
      this.allPublished = false;
    },
    setPublished() {
      if (this.allPublished) {
        this.$store.dispatch('setAll', false);
      } else {
        this.$store.dispatch('setAllPublished');
      }
    },
    setAll() {
      this.$store.dispatch('setAll', !this.all);
    },
    deleteSelected() {
      this.removeAllDialogue = false;
      axios.put('/api/myImages/deleteImages', { list: this.getSelected }).finally(() => {
        this.updateGallery();
      }).catch(() => {
        //
      });
    },
    unmarkImages() {
      axios.put('/api/myImages/unmark', { list: this.getSelected }).then((response) => {
        if (response.data.success) {
          this.updateGallery();
        }
      }).catch(() => {
        //
      });
    },
    uploadsClosed() {
      this.uploadsDialog = false;
      this.updateGallery();
    },
    uploadsPublicClosed() {
      this.uploadsPublicDialog = false;
      this.updateGallery();
    },
  },
};
</script>

<style scoped>
.images_menu{
  letter-spacing: unset;
  margin-left: 4px;
  margin-right: 4px;
  color: #16232e !important;
  font-size: small !important;
}
</style>
