<template lang="pug">
  v-dialog(:value="visible" width="500" @click:outside="close"
    @keydown.esc="close")
    v-card
      v-toolbar(color="success")
        v-btn(icon dark @click="close")
          v-icon mdi-close
        v-toolbar-title Confirmation
      v-card-text.mt-2
        span {{ ask }}
        p {{ text }}
      v-card-actions
        v-spacer
        v-btn(color="green darken-1"
          text
          @click="confirm") Agree
        v-btn(color="green darken-1"
          text
          @click="close") Disagree
</template>

<script>
export default {
  name: 'ConfirmDialogue',

  data() {
    return {
      ask: '',
      text: '',
      visible: false,
    };
  },

  methods: {
    show(opts = {}) {
      this.ask = opts.ask;
      this.text = opts.text;

      this.visible = true;
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    confirm() {
      this.visible = false;
      this.resolvePromise(true);
    },
    close() {
      this.visible = false;
      this.resolvePromise(false);
    },
  },
};
</script>
