<template lang="pug">
  v-app
    v-container
      loader(v-if="loader")
      regenerate-dialogue(
        :visible="regenerateDialogueVisible"
        @close="regenerateDialogueVisible = false"
        @accept="regenerate"
      )
      remove-dialogue(
        :visible="removeVisible"
        @close="removeVisible = false"
        @accept="remove"
      )
      edit-dialogue(
        :visible="editVisible"
        v-model="text"
        @close="editVisible = false"
        @accept="saveEdit"
        @copy="copyChangesQueue"
      )
      preview-image(
        :image="preview_image"
        :thumb="thumb_image"
        :visible="preview"
        @close="preview = false"
      )
      v-row
        v-col(cols=6)
          v-row.mb-1.ml-1
            v-avatar(size=48)
              v-img(class="mx-2" :src="page_logo")
            v-toolbar-title(class="ml-2")
              h3 {{ page_name }}
          v-sheet
            v-toolbar(height="40" color="success" flat)
              v-toolbar-title(v-if="$refs.calendar").white--text {{ $refs.calendar.title }}
              v-spacer
              v-btn(fab text small color="white" @click="prev")
                v-icon mdi-chevron-left
              v-btn(fab text small color="white" @click="next")
                v-icon mdi-chevron-right

          v-sheet(height="450")
            v-calendar(
              ref="calendar"
              v-model="focus"
              color="success"
              :events="events"
              :type="type"
              :weekdays="weekday"
              @click:date="viewDay"
              @click:more="viewDay"
              @change="updateRange"
            )

          v-app-bar.mb-2.mt-2
            v-toolbar-title(class="ml-2") Queue (100 Days)
            v-spacer
            v-btn.btn.edit-btn.text_white(color="success" @click="regenerateDialogueVisible = true"
              title='This will delete all the queue items \
              (then the queue will automatically slowly regenerate)')
              v-icon(right) fas fa-redo-alt
              | &nbsp; Regenerate
            v-btn.btn.edit-btn.text_white(
              color="success"
              title='This would take all the items currently in the queue and just \
              randomly re-order them'
              @click="shuffle"
            )
              v-icon(right) fas fa-random
              | &nbsp; Shuffle
          draggable(
            :list="queue"
            :disabled="!enabled"
            class="list-group"
            ghost-class="ghost"
            :move="checkMove"
            @start="dragging = true"
            @end="dragging = false"
          )
            v-card.draggable(
              v-for="item in queue"
              :key="item.ID"
              outlined
              class="queue-outer"
              :disabled="!item.locked"
            )
              v-img.queue-post(:src="`/${item.thumbnail}`" height="170" @click="clickRecord(item)")
                v-card-title(class="white--text")
                  v-col(
                    v-if="isLong(item)"
                    @mouseover.stop="hover_twitter = true"
                    @mouseleave.stop="hover_twitter = false"
                    md="8"
                  )
                    v-badge(color="error" icon="mdi-cancel" overlap)
                      v-avatar(size="36")
                        v-img(src="/images/setting/circle-twitter-60.png")
                    strong.red--text.text--lighten-1
                      | Character limit ({{ item.text.length }}/280).&nbsp;
                    strong.red--text.text--lighten-1.text-decoration-line-through Tweet
                    div(v-show="hover_twitter")
                      v-card-text.text_title
                        | The text content of a Tweet can contain up to 280 characters or Unicode
                        | glyphs! Some glyphs will count as more than one character.
                        | Make the text shorter!
                  span.post-time(v-else-if="item.locked")
                    | Published by Evergreen about {{ item.units }} from now
                  span.post-time(v-else)
                    | This post cannot be edited because it will be published in a few moments
                  v-spacer
                  template(v-if="item.locked")
                    v-btn(
                      color="success"
                      @click.stop="toTop(item)"
                      title='Move to the top.'
                      ).btn-queue
                      v-icon mdi-arrow-up
                    v-btn(color="success" @click.stop="showEdit(item)").btn-queue.ml-1
                      v-icon mdi-square-edit-outline
                    v-btn(color="error" @click.stop="showRemove(item)").btn-queue.ml-1
                      v-icon mdi-delete
              v-card-text {{ item.text }}
        v-col(cols=6)
          v-row.mb-1.ml-1
            h3 Posts on {{ dateTitle }}
            v-container(v-if="!queueDate.length && ready")
              h4.text-center No posts scheduled for this day
          draggable(
            :list="queueDate"
            :disabled="!enabled"
            class="list-group"
            ghost-class="ghost"
            :move="checkMoveDay"
            @start="dragging = true"
            @end="dragging = false"
          )
            v-card.draggable(
              v-for="item in queueDate"
              :key="item.ID"
              outlined
              class="queue-outer"
              :disabled="!item.locked"
            )
              v-img.queue-post(
                :src="`/${ thumb(item) }`" @click="clickRecord(item)").img_date
                v-card-title(class="white--text")
                  v-col(
                    v-if="isLong(item)"
                    @mouseover.stop="hover_twitter = true"
                    @mouseleave.stop="hover_twitter = false"
                    md="8"
                  )
                    v-badge(color="error" icon="mdi-cancel" overlap)
                      v-avatar(size="36")
                        v-img(src="/images/setting/circle-twitter-60.png")
                    strong.red--text.text--lighten-1
                      | Character limit ({{ item.text.length }}/280).&nbsp;
                    strong.red--text.text--lighten-1.text-decoration-line-through Tweet
                    div(v-show="hover_twitter")
                      v-card-text.text_title
                        | The text content of a Tweet can contain up to 280 characters or Unicode
                        | glyphs! Some glyphs will count as more than one character.
                        | Make the text shorter!
                  span.post-time(v-else-if="item.locked")
                    | Published by Evergreen about {{ item.units }} from now
                  span.post-time(v-else)
                    | This post cannot be edited because it will be published in a few moments
                  v-spacer
                  template(v-if="item.locked")
                    v-btn(
                      color="success"
                      @click.stop="toTop(item)"
                      title='Move to the top.'
                    ).btn-queue
                      v-icon mdi-arrow-up
                    v-btn(color="success" @click.stop="showEdit(item)").btn-queue.ml-1
                      v-icon mdi-square-edit-outline
                    v-btn(color="error" @click.stop="showRemove(item)").btn-queue.ml-1
                      v-icon mdi-delete
              v-card-text {{ item.text }}
</template>

<script>
import axios from 'axios';
import draggable from 'vuedraggable';
import _ from 'lodash';
import regenerateDialogue from './RegenerateDialogue';
import removeDialogue from './RemoveDialogue';
import editDialogue from './EditDialogue';
import previewImage from '../common/PreviewImage';
import loader from '../Loader';

export default {
  name: 'Queue',

  components: {
    regenerateDialogue,
    removeDialogue,
    editDialogue,
    previewImage,
    draggable,
    loader,
  },

  data() {
    return {
      ready: false,
      regenerateDialogueVisible: false,
      removeVisible: false,
      editVisible: false,
      copyChanges: false,
      shid: 0,
      dragging: true,
      enabled: true,
      loader: false,
      preview: false,
      hover_twitter: false,
      preview_image: '',
      thumb_image: '',
      id: 0,
      text: '',
      timeout: 0,
      page_name: '',
      page_logo: '',
      focus: '',
      type: 'month',
      events: [],
      weekday: [1, 2, 3, 4, 5, 6, 0],
      selectedDay: null,
      date: '',
      dateTitle: '',
      queueDate: [],
      start: '',
      end: '',
      db_date: '',
    };
  },

  computed: {
    queue() {
      return this.$store.state.queue;
    },
  },
  watch: {
    queue() {
      this.filterDate();
    },
  },

  mounted() {
    this.shid = new URL(window.location.href).searchParams.get('id');
    this.getQueue();
    const today = new Date();
    this.date = this.setDate(today);
    this.dateTitle = this.setTitleDate(today);
  },

  methods: {
    clickRecord(item) {
      this.preview_image = `/${item.filename}`;
      this.thumb_image = `/${this.thumb(item)}`;
      this.preview = true;
    },
    copyChangesQueue() {
      this.copyChanges = !this.copyChanges;
    },
    regenerate() {
      this.regenerateDialogueVisible = false;
      this.loader = true;
      axios.delete(
        '/api/queue/regenerate',
        { data: { shid: this.shid } },
      ).then((response) => {
        this.$store.commit('setQueue', response.data.featured);
        this.loader = false;
      }).catch(() => {
        this.loader = false;
      });
    },

    shuffle() {
      this.loader = true;
      axios.patch(
        '/api/queue/shuffle',
        { shid: this.shid },
      ).then((response) => {
        this.$store.commit('setQueue', response.data.featured);
        this.loader = false;
      }).catch(() => {
        this.loader = false;
      });
    },

    toTop(item) {
      this.loader = true;
      const data = { shid: this.shid, id: item.ID };

      axios.patch('/api/queue/to_top', data).then((response) => {
        this.$store.commit('setQueue', response.data.featured);
        this.loader = false;
        this.filterDate();
      }).catch(() => {
        this.loader = false;
      });
    },

    remove() {
      this.loader = true;
      this.removeVisible = false;
      axios.delete('/api/queue/item', { data: { ID: this.id } }).then(() => {
        this.$store.dispatch('removeQueue', this.id);
        this.loader = false;
      }).catch(() => {
        this.loader = false;
      });
    },

    setOrder() {
      this.orderRequest(this.queue, 0);
    },
    setOrderDay() {
      this.orderRequest(this.queueDate, 1);
    },
    orderRequest(queue, day) {
      const order = [];
      _.each(queue, (item) => {
        order.push(item.ID);
      });
      this.loader = true;
      axios.put('/api/queue/order', { order, day, shid: this.shid }).then((response) => {
        this.$store.commit('setQueue', response.data.featured);
        this.loader = false;
      });
    },

    saveEdit() {
      const data = { ID: this.id, text: this.text, copy: this.copyChanges };

      axios.put('/api/queue/item', data).then(() => {
        this.$store.commit('editQueue', { id: this.id, text: this.text });
        this.editVisible = false;
      }).catch(() => {
        this.editVisible = false;
      });
    },

    getQueue() {
      this.loader = true;
      axios.get(`/api/queue/getList/${this.shid}`).then((response) => {
        if (response.data.featured) {
          this.page_logo = response.data.featured[0].page_logo;
          this.page_name = response.data.featured[0].scheduler.page_name;
        }
        this.$store.commit('setQueue', response.data.featured);
        this.db_date = _.get(response, 'data.db_date', '');
        this.loader = false;
        this.updateRange({ start: this.start, end: this.end });
        this.ready = true;
      }).catch(() => {
        this.loader = false;
      });
    },

    checkMove() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.setOrder();
      }, 2000);
    },
    checkMoveDay() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.setOrderDay();
      }, 2000);
    },

    showRemove(item) {
      this.id = item.ID;
      this.removeVisible = true;
    },

    showEdit(item) {
      this.id = item.ID;
      this.text = item.text;
      this.editVisible = true;
    },
    isLong(item) {
      const caption = item.text;
      const social = Number(item.scheduler.social.SID);

      return social === 2 && caption.length >= 280;
    },
    // calendar
    viewDay(date) {
      this.date = date.date;
      const dateCurrent = Date.parse(date.date);
      this.dateTitle = this.setTitleDate(new Date(dateCurrent));
      this.filterDate();
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    updateRange({ start, end }) {
      this.start = start;
      this.end = end;
      const dayMicSeconds = 86400000;
      const events = [];
      let day = '';
      let time = 0;
      const minutesDiff = this.getTimezone();
      const min = new Date(`${start.date}T00:00:00`).getTime() - minutesDiff;
      const max = new Date(`${end.date}T23:59:59`).getTime() - minutesDiff;
      const days = Math.round((max - min) / dayMicSeconds);

      for (let i = 0; i < days; i += 1) {
        if (i > 0) {
          time += dayMicSeconds;
        } else {
          time = min;
        }
        day = this.setEventDate(new Date(time));
        const count = this.eventsDay(day);

        if (count) {
          events.push({
            name: `Posts: ${count}`,
            start: time,
            color: 'warning',
            timed: false,
          });
        }
      }
      this.events = events;
    },
    setTitleDate(time) {
      const day = time.toLocaleString('en-us', { day: 'numeric' });
      const mount = time.toLocaleString('en-us', { month: 'short' });
      const year = time.toLocaleString('en-us', { year: 'numeric' });
      return `${day} ${mount} ${year}`;
    },
    setEventDate(time) {
      const day = time.toLocaleString('en-us', { day: '2-digit' });
      const month = time.toLocaleString('en-us', { month: '2-digit' });
      const year = time.toLocaleString('en-us', { year: 'numeric' });
      return `${year}-${month}-${day}`;
    },
    setDate(time) {
      const year = time.getFullYear();
      let month = time.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      }
      let day = time.getDate();
      if (day < 10) {
        day = `0${day}`;
      }
      return `${year}-${month}-${day}`;
    },
    getTimezone() {
      const timeClient = new Date().getTime();
      const timeServer = new Date(this.db_date).getTime();
      return Math.round(timeClient - timeServer);
    },
    filterDate() {
      this.queueDate = [];
      _.each(this.queue, (item) => {
        if (item.will_publish.includes(this.date)) {
          this.queueDate.push(item);
        }
      });
    },
    eventsDay(day) {
      let events = 0;
      _.each(this.queue, (item) => {
        if (item.will_publish.includes(day)) {
          events += 1;
        }
      });
      return events;
    },
    thumb(item) {
      return item.type === 'video' ? item.thumbVideo : item.filename;
    },
  },
};
</script>

<style>
  .button_queue{
    background: rgba(255, 255, 255, 0.8);
    margin: 0 1px;
  }
  .post-time{
    display:block;
    color: #ffffff;
    font-weight:500;
    font-size:15px;
    line-height:normal;
    text-transform: none;
    background: rgba(128, 128, 128, 0.3) !important;
    padding: 6px;
    border-radius: 2px;
    text-shadow: black 1px 1px 0, black -1px -1px 0, black -1px 1px 0, black 1px -1px 0;
  }
  .draggable {
    cursor: move;
  }
  .v-card__text {
    color: #525252;
  }
  .queue-outer{
    max-width:754px;
    width:100%;
    margin-bottom:35px;
  }
  .queue-outer .white-board{
    padding:18px 16px;
  }
  .queue-outer:hover{
    cursor: move;
  }
  .queue-post{
    height:170px;
    overflow:hidden;
  }
  .text_title{
    position: absolute;
    background-color: white;
  }
</style>
<style scoped>
.btn-queue {
  height: unset !important;
  min-width: unset !important;
  padding: 4px !important;
}
.img_date{
  height: 100% !important;
}
</style>
