<template lang="pug">
  v-app
    v-text-field(color="success" placeholder='Enter library name' v-model="library")
    v-btn(color="success" large @click.native="createLib()")
      v-icon(left) mdi-plus
      | Create
    v-list(dense)
      v-list-item-group(color="success" v-model="selectedLibrary")
        v-list-item(
          v-for="(library, key) in list"
          :key="key"
          @click="setLibrary(library.ID, library.name, key)"
          )
          p(v-show="library.limitation" title="Limitations")
            v-badge(color="error" overlap left :content="library.limitation")
          v-list-item-content
            v-list-item-title(v-text="library.name")
          v-list-item-action
            v-btn(icon small @click.native="editVisible=true")
              v-icon() mdi-square-edit-outline
            v-btn(icon small @click.native="removeVisible=true")
              v-icon() mdi-delete
    edit-dialogue(
      :visible="editVisible"
      label="Library name:"
      v-model="editName"
      @close="editVisible=false"
      @accept="updateLib"
    )
    v-dialog(:value="removeVisible" max-width="290" @click:outside="closeRemove"
      @keydown.esc="closeRemove")
      v-card
        v-toolbar(color="success")
          v-btn(icon dark @click="removeVisible=false")
            v-icon mdi-close
          v-toolbar-title Remove library
        v-card-text Are you sure you want to delete the following library?
        v-card-text(v-if="editName") {{editName}}
        v-divider
        v-card-actions
          v-spacer
          v-btn(color="green darken-1"
            text
            @click="removeLib()") OK
          v-btn(color="green darken-1"
            text
            @click="closeRemove") Cancel

</template>

<script>

import _ from 'lodash';
import axios from 'axios';

import EditDialogue from '../common/EditDialogue';

export default {
  name: 'List',
  components: {
    EditDialogue,
  },
  data() {
    return {
      library: '',
      selectedLibrary: 0,
      index: 0,
      removeVisible: false,
      editVisible: false,
      editName: '',
      libraryId: null,
    };
  },
  computed: {
    list() {
      return this.$store.state.myLibraries;
    },
  },
  watch: {
    list() {
      this.getList();
    },
    selectedLibrary() {
      if (typeof this.selectedLibrary === 'undefined') {
        setTimeout(() => {
          this.selectedLibrary = this.index;
        }, 500);
      } else {
        window.app.$refs.myImages.galleryId = this.libraryId;
      }
    },
  },
  mounted() {
    //
  },

  methods: {
    getList() {
      if (this.list) {
        window.app.$refs.myImages.galleryId = this.list[0].ID;
        this.selectedLibrary = 0;
      }
    },
    setLibrary(id, name, key) {
      this.libraryId = id;
      this.editName = name;
      this.index = key;
    },
    createLib() {
      axios.put(`/api/gallery/${this.library}`).then((response) => {
        const id = _.get(response, 'data.id', false);
        if (id) {
          const row = { ID: id, name: this.library };
          this.$store.commit('pushMyLibraries', row);
          this.library = '';
        }
      });
    },
    updateLib() {
      axios.post(
        `/api/gallery/${this.editName}`,
        { id: this.libraryId },
      ).then(() => {
        const row = { ID: this.libraryId, name: this.editName };
        this.$store.commit('editLibrary', row);
        this.libraryId = null;
        this.editName = '';
      });
      this.editVisible = false;
    },
    closeRemove() {
      this.removeVisible = false;
    },

    removeLib() {
      axios.delete(`/api/gallery/${this.libraryId}`).then(() => {
        this.$store.commit('removeLibrary', this.libraryId);
      });
      this.removeVisible = false;
    },
  },
};
</script>

<style scoped>
  .v-text-field {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
  .v-list-item__action {
    margin-left: 0 !important;
    display: contents !important;
  }
  .v-list-item{
    padding-right: 0 !important;
  }
  .v-input{
    flex: none !important;
  }
  .v-badge {
    left: -16px !important;
  }
</style>
