var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.visible, "max-width": "80vh", "max-height": "80vh" },
      on: {
        "click:outside": function($event) {
          return _vm.$emit("close")
        },
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.$emit("close")
        }
      }
    },
    [
      _c("v-img", {
        attrs: { contain: "", src: _vm.image, "lazy-src": _vm.thumb }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }