var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { value: _vm.visible, width: "600" },
          on: {
            "click:outside": _vm.close,
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.close($event)
            }
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "success" } },
                [
                  _c(
                    "v-btn",
                    { attrs: { icon: "", dark: "" }, on: { click: _vm.close } },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                  _c("v-toolbar-title", [_vm._v("Twitter Limitation")])
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c("v-container", { staticClass: "body-2" }, [
                    _c("h3", [
                      _vm._v("Image specifications and recommendations")
                    ]),
                    _c("p", [
                      _vm._v(
                        "Image files must meet all of the following criteria:"
                      )
                    ]),
                    _c("ol", [
                      _c("li", [
                        _vm._v("Supported image media types: JPG, PNG, GIF")
                      ]),
                      _c("li", [
                        _vm._v("Image size <= 5 MB, animated GIF size <= 15 MB")
                      ])
                    ]),
                    _c("h3", [_vm._v("Animated GIF recommendations")]),
                    _c("p", [
                      _vm._v(
                        "A GIF may fail during Tweet creation even if it is within the file size limit.Adhere to the following constraints to improve success rates."
                      ),
                      _c("ol", [
                        _c("li", [
                          _vm._v(
                            "Resolution should be <= 1280x1080 (width x height)"
                          )
                        ]),
                        _c("li", [_vm._v("Number of frames <= 350")]),
                        _c("li", [
                          _vm._v(
                            "Number of pixels (width * height * num_frames) <= 300 million"
                          )
                        ]),
                        _c("li", [_vm._v("File size <= 15Mb")])
                      ])
                    ]),
                    _c("h3", [
                      _vm._v("Video specifications and recommendations")
                    ]),
                    _c("p", [_vm._v("Recommended:")]),
                    _c("ol", [
                      _c("li", [_vm._v("Video Codec: H264 High Profile")]),
                      _c("li", [_vm._v("Frame Rates: 30 FPS, 60 FPS")]),
                      _c("li", [
                        _vm._v(
                          "Video Resolution: 1280x720 (landscape), 720x1280 (portrait), 720x720 (square)"
                        )
                      ]),
                      _c("li", [_vm._v("Minimum Video Bitrate: 5,000 kbps")]),
                      _c("li", [_vm._v("Minimum Audio Bitrate: 128 kbps")]),
                      _c("li", [_vm._v("Audio Codec: AAC LC")]),
                      _c("li", [
                        _vm._v(
                          "Aspect Ratio: 16:9 (landscape or portrait), 1:1 (square)"
                        )
                      ])
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }