var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-container",
        [
          _vm.loader ? _c("loader") : _vm._e(),
          _c("regenerate-dialogue", {
            attrs: { visible: _vm.regenerateDialogueVisible },
            on: {
              close: function($event) {
                _vm.regenerateDialogueVisible = false
              },
              accept: _vm.regenerate
            }
          }),
          _c("remove-dialogue", {
            attrs: { visible: _vm.removeVisible },
            on: {
              close: function($event) {
                _vm.removeVisible = false
              },
              accept: _vm.remove
            }
          }),
          _c("edit-dialogue", {
            attrs: { visible: _vm.editVisible },
            on: {
              close: function($event) {
                _vm.editVisible = false
              },
              accept: _vm.saveEdit,
              copy: _vm.copyChangesQueue
            },
            model: {
              value: _vm.text,
              callback: function($$v) {
                _vm.text = $$v
              },
              expression: "text"
            }
          }),
          _c("preview-image", {
            attrs: {
              image: _vm.preview_image,
              thumb: _vm.thumb_image,
              visible: _vm.preview
            },
            on: {
              close: function($event) {
                _vm.preview = false
              }
            }
          }),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "mb-1 ml-1" },
                    [
                      _c(
                        "v-avatar",
                        { attrs: { size: "48" } },
                        [
                          _c("v-img", {
                            staticClass: "mx-2",
                            attrs: { src: _vm.page_logo }
                          })
                        ],
                        1
                      ),
                      _c("v-toolbar-title", { staticClass: "ml-2" }, [
                        _c("h3", [_vm._v(_vm._s(_vm.page_name))])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-sheet",
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { height: "40", color: "success", flat: "" } },
                        [
                          _vm.$refs.calendar
                            ? _c(
                                "v-toolbar-title",
                                { staticClass: "white--text" },
                                [_vm._v(_vm._s(_vm.$refs.calendar.title))]
                              )
                            : _vm._e(),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                fab: "",
                                text: "",
                                small: "",
                                color: "white"
                              },
                              on: { click: _vm.prev }
                            },
                            [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                fab: "",
                                text: "",
                                small: "",
                                color: "white"
                              },
                              on: { click: _vm.next }
                            },
                            [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-sheet",
                    { attrs: { height: "450" } },
                    [
                      _c("v-calendar", {
                        ref: "calendar",
                        attrs: {
                          color: "success",
                          events: _vm.events,
                          type: _vm.type,
                          weekdays: _vm.weekday
                        },
                        on: {
                          "click:date": _vm.viewDay,
                          "click:more": _vm.viewDay,
                          change: _vm.updateRange
                        },
                        model: {
                          value: _vm.focus,
                          callback: function($$v) {
                            _vm.focus = $$v
                          },
                          expression: "focus"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-app-bar",
                    { staticClass: "mb-2 mt-2" },
                    [
                      _c("v-toolbar-title", { staticClass: "ml-2" }, [
                        _vm._v("Queue (100 Days)")
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn edit-btn text_white",
                          attrs: {
                            color: "success",
                            title:
                              "This will delete all the queue items             (then the queue will automatically slowly regenerate)"
                          },
                          on: {
                            click: function($event) {
                              _vm.regenerateDialogueVisible = true
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { right: "" } }, [
                            _vm._v("fas fa-redo-alt")
                          ]),
                          _vm._v("  Regenerate")
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn edit-btn text_white",
                          attrs: {
                            color: "success",
                            title:
                              "This would take all the items currently in the queue and just             randomly re-order them"
                          },
                          on: { click: _vm.shuffle }
                        },
                        [
                          _c("v-icon", { attrs: { right: "" } }, [
                            _vm._v("fas fa-random")
                          ]),
                          _vm._v("  Shuffle")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "draggable",
                    {
                      staticClass: "list-group",
                      attrs: {
                        list: _vm.queue,
                        disabled: !_vm.enabled,
                        "ghost-class": "ghost",
                        move: _vm.checkMove
                      },
                      on: {
                        start: function($event) {
                          _vm.dragging = true
                        },
                        end: function($event) {
                          _vm.dragging = false
                        }
                      }
                    },
                    _vm._l(_vm.queue, function(item) {
                      return _c(
                        "v-card",
                        {
                          key: item.ID,
                          staticClass: "draggable queue-outer",
                          attrs: { outlined: "", disabled: !item.locked }
                        },
                        [
                          _c(
                            "v-img",
                            {
                              staticClass: "queue-post",
                              attrs: {
                                src: "/" + item.thumbnail,
                                height: "170"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.clickRecord(item)
                                }
                              }
                            },
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "white--text" },
                                [
                                  _vm.isLong(item)
                                    ? _c(
                                        "v-col",
                                        {
                                          attrs: { md: "8" },
                                          on: {
                                            mouseover: function($event) {
                                              $event.stopPropagation()
                                              _vm.hover_twitter = true
                                            },
                                            mouseleave: function($event) {
                                              $event.stopPropagation()
                                              _vm.hover_twitter = false
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-badge",
                                            {
                                              attrs: {
                                                color: "error",
                                                icon: "mdi-cancel",
                                                overlap: ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-avatar",
                                                { attrs: { size: "36" } },
                                                [
                                                  _c("v-img", {
                                                    attrs: {
                                                      src:
                                                        "/images/setting/circle-twitter-60.png"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "strong",
                                            {
                                              staticClass:
                                                "red--text text--lighten-1"
                                            },
                                            [
                                              _vm._v(
                                                "Character limit (" +
                                                  _vm._s(item.text.length) +
                                                  "/280). "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "strong",
                                            {
                                              staticClass:
                                                "red--text text--lighten-1 text-decoration-line-through"
                                            },
                                            [_vm._v("Tweet")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.hover_twitter,
                                                  expression: "hover_twitter"
                                                }
                                              ]
                                            },
                                            [
                                              _c(
                                                "v-card-text",
                                                { staticClass: "text_title" },
                                                [
                                                  _vm._v(
                                                    "The text content of a Tweet can contain up to 280 characters or Unicode\nglyphs! Some glyphs will count as more than one character.\nMake the text shorter!"
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : item.locked
                                    ? _c("span", { staticClass: "post-time" }, [
                                        _vm._v(
                                          "Published by Evergreen about " +
                                            _vm._s(item.units) +
                                            " from now"
                                        )
                                      ])
                                    : _c("span", { staticClass: "post-time" }, [
                                        _vm._v(
                                          "This post cannot be edited because it will be published in a few moments"
                                        )
                                      ]),
                                  _c("v-spacer"),
                                  item.locked
                                    ? [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "btn-queue",
                                            attrs: {
                                              color: "success",
                                              title: "Move to the top."
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.toTop(item)
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-arrow-up")
                                            ])
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "btn-queue ml-1",
                                            attrs: { color: "success" },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.showEdit(item)
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-square-edit-outline")
                                            ])
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "btn-queue ml-1",
                                            attrs: { color: "error" },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.showRemove(item)
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", [_vm._v("mdi-delete")])
                                          ],
                                          1
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c("v-card-text", [_vm._v(_vm._s(item.text))])
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "mb-1 ml-1" },
                    [
                      _c("h3", [_vm._v("Posts on " + _vm._s(_vm.dateTitle))]),
                      !_vm.queueDate.length && _vm.ready
                        ? _c("v-container", [
                            _c("h4", { staticClass: "text-center" }, [
                              _vm._v("No posts scheduled for this day")
                            ])
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "draggable",
                    {
                      staticClass: "list-group",
                      attrs: {
                        list: _vm.queueDate,
                        disabled: !_vm.enabled,
                        "ghost-class": "ghost",
                        move: _vm.checkMoveDay
                      },
                      on: {
                        start: function($event) {
                          _vm.dragging = true
                        },
                        end: function($event) {
                          _vm.dragging = false
                        }
                      }
                    },
                    _vm._l(_vm.queueDate, function(item) {
                      return _c(
                        "v-card",
                        {
                          key: item.ID,
                          staticClass: "draggable queue-outer",
                          attrs: { outlined: "", disabled: !item.locked }
                        },
                        [
                          _c(
                            "v-img",
                            {
                              staticClass: "queue-post img_date",
                              attrs: { src: "/" + _vm.thumb(item) },
                              on: {
                                click: function($event) {
                                  return _vm.clickRecord(item)
                                }
                              }
                            },
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "white--text" },
                                [
                                  _vm.isLong(item)
                                    ? _c(
                                        "v-col",
                                        {
                                          attrs: { md: "8" },
                                          on: {
                                            mouseover: function($event) {
                                              $event.stopPropagation()
                                              _vm.hover_twitter = true
                                            },
                                            mouseleave: function($event) {
                                              $event.stopPropagation()
                                              _vm.hover_twitter = false
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-badge",
                                            {
                                              attrs: {
                                                color: "error",
                                                icon: "mdi-cancel",
                                                overlap: ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-avatar",
                                                { attrs: { size: "36" } },
                                                [
                                                  _c("v-img", {
                                                    attrs: {
                                                      src:
                                                        "/images/setting/circle-twitter-60.png"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "strong",
                                            {
                                              staticClass:
                                                "red--text text--lighten-1"
                                            },
                                            [
                                              _vm._v(
                                                "Character limit (" +
                                                  _vm._s(item.text.length) +
                                                  "/280). "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "strong",
                                            {
                                              staticClass:
                                                "red--text text--lighten-1 text-decoration-line-through"
                                            },
                                            [_vm._v("Tweet")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.hover_twitter,
                                                  expression: "hover_twitter"
                                                }
                                              ]
                                            },
                                            [
                                              _c(
                                                "v-card-text",
                                                { staticClass: "text_title" },
                                                [
                                                  _vm._v(
                                                    "The text content of a Tweet can contain up to 280 characters or Unicode\nglyphs! Some glyphs will count as more than one character.\nMake the text shorter!"
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : item.locked
                                    ? _c("span", { staticClass: "post-time" }, [
                                        _vm._v(
                                          "Published by Evergreen about " +
                                            _vm._s(item.units) +
                                            " from now"
                                        )
                                      ])
                                    : _c("span", { staticClass: "post-time" }, [
                                        _vm._v(
                                          "This post cannot be edited because it will be published in a few moments"
                                        )
                                      ]),
                                  _c("v-spacer"),
                                  item.locked
                                    ? [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "btn-queue",
                                            attrs: {
                                              color: "success",
                                              title: "Move to the top."
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.toTop(item)
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-arrow-up")
                                            ])
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "btn-queue ml-1",
                                            attrs: { color: "success" },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.showEdit(item)
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-square-edit-outline")
                                            ])
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "btn-queue ml-1",
                                            attrs: { color: "error" },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.showRemove(item)
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", [_vm._v("mdi-delete")])
                                          ],
                                          1
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c("v-card-text", [_vm._v(_vm._s(item.text))])
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }