<template lang="pug">
  v-container
    v-container(v-show="main")
      .text-left.mb-4.social
        v-btn(fab @click.native="setFb" title="Facebook pages").mr-5
          v-img(src='/images/setting/icono-fb.png' width='60')
        v-btn(fab @click.native="setFbGroup" title="Facebook groups").mr-5
          v-img(src='/images/setting/icono-fbg.png' width='68' style="border-radius: 34px;")
        v-btn(fab @click.native="setTw"  title="Twitter account").mr-5
          v-img(src='/images/setting/circle-twitter-60.png' width='65')
        v-btn(fab @click.native="setLinkedIn"  title="LinkedIn account, companies").mr-5
          v-img(src='/images/setting/lnkdin.png' width='60' style="border-radius: 30px;")
        v-btn(fab @click.native="setInstagram"  title="Instagram pages")
          v-img(src='/images/setting/instagram-60.jpeg' width='60' style="border-radius: 30px;")
      v-spacer
      p(v-if="ready && limit") You can add
        strong &nbsp;{{limit}}&nbsp;
        | more pages or accounts. Please choose between Facebook, Twitter, LinkedIn, Instagram
        | then ADD!
      p(v-if="ready && !limit")
        v-icon(color="warning") mdi-alert
        |You can't add more pages or twitter accounts, please &nbsp;
        v-btn(large color="error" href="/api/packages") UPGRADE PLAN
      v-btn(large href="/api/setting/reconnect" color="primary")
        v-icon mdi-reload
        | &nbsp;Reconnect Socials/Cloud
    v-btn(large v-show="!main" color="primary" @click.native="reset")
      v-icon mdi-arrow-left-circle
      | Back
    v-container(v-show="fb")
      v-row
        v-col(cols='6').pl-0
          v-select(
            :items="fb_pages"
            v-model="selectedPage"
            label="Please select facebook page"
            item-text='page_name'
            item-value='page_id'
            dense
            :menu-props="{ maxHeight: '450' }"
            ).mr-4
        v-col(cols='6')
          v-btn(large color="success" @click.native="addPage(def.FB)").mr-4 Add now
          v-btn(large @click.native="refreshFb" :loading="refreshing").mr-4 Refresh
      p(v-show="!fb_pages.length")
        v-icon(color="info") mdi-information
        |All of your connected pages been set up for publishing.
      p
        v-icon(color="info") mdi-information
        |Try checking your Facebook permissions to set up pages or groups not yet connected
        |to Evergreen.
    v-container(v-show="fb_group")
      v-row
        v-col(cols='6').pl-0
          v-select(
            :items="fb_groups"
            v-model="selectedPage"
            label="Please select facebook group"
            item-text='page_name'
            item-value='page_id'
            dense
            :menu-props="{ maxHeight: '450' }"
            ).mr-4
        v-col(cols='6')
          v-btn(large color="success" @click.native="addPage(def.FB_GROUP)").mr-4 Add now
          v-btn(large @click.native="refreshFbGroups" :loading="refreshing").mr-4 Refresh
      p(v-show="!fb_groups.length")
        v-icon(color="info") mdi-information
        |All of your connected groups been set up for publishing.
      p
        v-icon(color="info") mdi-information
        |Try checking your Facebook permissions to set up pages or groups not yet connected
        |to Evergreen.
    v-container(v-show="tw")
      v-row.pl-0.mt-1
        v-btn(large color="info" @click.native="addTw").mr-4
          v-icon() mdi-twitter
          |Add Twitter account
    v-container(v-show="linkedIn")
      v-row
        v-col(cols="3").pl-0
          v-btn(large color="info" @click.native="addLinkedIn").mr-4
            v-icon mdi-linkedin
            | Add LinkedIn account
        v-col(cols="6")
          v-select(
            :items="linkedIn_companies"
            v-model="selectedPage"
            label="Please select LinkedIn company"
            item-text='page_name'
            item-value='page_id'
            dense
            :menu-props="{ maxHeight: '450' }"
            ).mr-4
        v-col(cols='3')
          v-btn(large color="success" @click.native="addPage(def.LINKEDIN)").mr-4 Add now
          v-btn(large @click.native="refreshLinkedIn" :loading="refreshing") Refresh
      p(v-show="!linkedIn_companies.length")
        v-icon(color="info") mdi-information
        |All of your connected companies been set up for publishing.
      p
        v-icon(color="info") mdi-information
        |If company doesn't arrive in list click REFRESH
    v-container(v-show="instagram")
      v-row
        v-col(cols="6")
          v-select(
            :items="instagram_pages"
            v-model="selectedPage"
            label="Please select Instagram page"
            item-text='page_name'
            item-value='page_id'
            dense
            :menu-props="{ maxHeight: '450' }"
            ).mr-4
        v-col(cols='6')
          v-btn(large color="success" @click.native="addPage(def.INSTAGRAM)").mr-4 Add now
          v-btn(large @click.native="refreshInstagram" :loading="refreshing") Refresh
      p(@click="instagramDialog=true")
        v-icon(color="info") mdi-information
        | Having trouble?
        a &nbsp; Connecting to Instagram instructions
      p(v-show="!instagram_pages.length")
        v-icon(color="info") mdi-information
        |All of your connected pages been set up for publishing.
      p
        v-icon(color="info") mdi-information
        |Try checking your Facebook permissions to set up Instagram pages
        |not yet connected to Evergreen.
    alert-message(ref='alertComponent')
    instagram-dialogue(:visible="instagramDialog" @close="instagramDialog=false")
</template>

<script>
import axios from 'axios';
import _ from 'lodash';

import AlertMessage from '../common/AlertMessage';
import InstagramDialogue from './InstagramDialogue';

const DEFINE = {
  FB: 'fb',
  FB_GROUP: 'fb_group',
  TWITTER: 'twitter',
  LINKEDIN: 'linkedIn',
  INSTAGRAM: 'instagram',
};

export default {
  name: 'Socials',
  components: {
    'alert-message': AlertMessage,
    'instagram-dialogue': InstagramDialogue,
  },
  props: {
    limit: {
      type: Number,
      default: 0,
    },
    ready: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fb: false,
      fb_group: false,
      tw: false,
      linkedIn: false,
      instagram: false,
      instagramDialog: false,
      main: true,
      fb_pages: [],
      fb_groups: [],
      linkedIn_companies: [],
      instagram_pages: [],
      plan: [],
      refreshing: false,
      selectedPage: '',
    };
  },
  computed: {
    def() { return DEFINE; },
  },
  created() {
    this.getData();
  },
  mounted() {
    this.addedPage();
  },
  methods: {
    reset() {
      this.fb = false;
      this.fb_group = false;
      this.tw = false;
      this.linkedIn = false;
      this.instagram = false;
      this.refreshing = false;
      this.main = true;
      this.$refs.alertComponent.hideMessages();
    },
    getData() {
      axios.get('/api/settings/socials').then((response) => {
        this.fb_pages = _.get(response, 'data.fb_pages', []);
        this.fb_groups = _.get(response, 'data.fb_groups', []);
        this.linkedIn_companies = _.get(response, 'data.linkedIn_companies', []);
        this.instagram_pages = _.get(response, 'data.instagram_pages', []);
        this.plan = _.get(response, 'data.plan', 0);
      });
    },
    setFb() {
      this.fb = true;
      this.main = false;
    },
    setFbGroup() {
      this.fb_group = true;
      this.main = false;
    },
    setTw() {
      this.tw = true;
      this.main = false;
    },
    setLinkedIn() {
      this.linkedIn = true;
      this.main = false;
    },
    setInstagram() {
      this.instagram = true;
      this.main = false;
    },
    addPage(social) {
      const data = { addpages: this.selectedPage };
      axios.post('/api/setting/add_page', data).then(() => {
        this.$refs.alertComponent.showSuccess('Added successfully!');
        this.removePage(social);
        this.$emit('updateSettings');
      });
    },
    removePage(social) {
      let index = 0;
      switch (social) {
        case this.def.FB:
          index = this.fb_pages.map((item) => String(item.page_id))
            .indexOf(String(this.selectedPage));
          this.fb_pages.splice(index, 1);
          break;
        case this.def.FB_GROUP:
          index = this.fb_groups.map((item) => String(item.page_id))
            .indexOf(String(this.selectedPage));
          this.fb_groups.splice(index, 1);
          break;
        case this.def.LINKEDIN:
          index = this.linkedIn_companies.map((item) => String(item.page_id))
            .indexOf(String(this.selectedPage));
          this.linkedIn_companies.splice(index, 1);
          break;
        case this.def.INSTAGRAM:
          index = this.instagram_pages.map((item) => String(item.page_id))
            .indexOf(String(this.selectedPage));
          this.instagram_pages.splice(index, 1);
          break;
        default:
          break;
      }
    },
    addTw() {
      window.location.href = '/api/twitter-redirect';
    },
    addLinkedIn() {
      window.location.href = '/api/linkedin/redirect';
    },
    refreshFb() {
      this.refreshing = true;
      axios.get('/api/refreshPages').then((response) => {
        this.fb_pages = _.get(response, 'data.pages', []);
      }).finally(() => {
        this.refreshing = false;
      });
    },
    refreshFbGroups() {
      this.refreshing = true;
      axios.get('/api/refreshGroups').then((response) => {
        this.fb_groups = _.get(response, 'data.groups', []);
      }).finally(() => {
        this.refreshing = false;
      });
    },
    refreshLinkedIn() {
      this.refreshing = true;
      axios.get('/api/refreshLinkedIn').then((response) => {
        this.linkedIn_companies = _.get(response, 'data.pages', []);
      }).finally(() => {
        this.refreshing = false;
      });
    },
    refreshInstagram() {
      this.refreshing = true;
      axios.get('/api/refreshInstagram').then((response) => {
        this.instagram_pages = _.get(response, 'data.pages', []);
      }).finally(() => {
        this.refreshing = false;
      });
    },
    addedPage() {
      const success = new URL(window.location.href).searchParams.get('success');
      const error = new URL(window.location.href).searchParams.get('error');
      const social = new URL(window.location.href).searchParams.get('social');

      if (success && social === DEFINE.TWITTER) {
        this.$refs.alertComponent.showSuccess('Twitter account added successfully!');
      } else if (error) {
        this.$refs.alertComponent.showError(error);
      }
    },
  },
};
</script>

<style scoped>
  .social >>> .v-image__image--cover, .v-image__image{
    background-size: contain !important;
  }
</style>
