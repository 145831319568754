<template lang="pug">
  v-dialog(:value="visible" @click:outside="close" @keydown.esc="close" max-width="400")
    v-card
      v-toolbar(color="success")
        v-btn(icon dark @click="close")
          v-icon mdi-close
        v-toolbar-title Check Permissions
      v-card-text
        v-container
          v-radio-group(v-model="force")
            v-radio(label="Normal mode" value=false color="success")
            v-radio(
              label="Force mode. Edit all permission. Use it rarely if normal mode didn't help!"
              value=true
              color="success"
              )
      v-card-actions
        v-spacer
        v-btn(
          color="success"
          @click="check"
          class="mx-2"
          text
          :loading="loading"
          ) YES, Check Permissions
</template>

<script>

import axios from 'axios';

export default {
  name: 'Permissions',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      force: 0,
      loading: false,
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    check() {
      this.loading = true;
      if (Number(this.force)) {
        axios.get('/api/fb/reset').then(() => {
          this.loading = false;
          window.location.href = '/api/fb/redirect';
        });
      } else {
        window.location.href = '/api/fb/redirect';
      }
    },

  },
};
</script>

<style scoped>

</style>
