<template lang="pug">
  v-dialog(:value="visible" @click:outside="close"
    @keydown.esc="close" scrollable max-width="500px")
    v-card
      v-toolbar(color="success")
        v-btn(icon dark @click="close")
          v-icon mdi-close
        v-toolbar-title Select Library
      v-card-text.dialog_size
        v-select(
          v-model="library_id"
          label="Select the library"
          :items="libraries"
          item-text="name"
          item-value="ID"
          attach
          append-icon="mdi-menu-down"
          :menu-props="{ maxHeight: '300' }"
        )
      v-card-actions
        v-spacer
        v-btn(color="green darken-1"
          text
          @click="submit"
          ) Continue

</template>

<script>
import _ from 'lodash';

export default {
  name: 'LibraryDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    libraries: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      library_id: 0,
      library_name: '',
    };
  },
  watch: {
    library_id() {
      const item = _.find(this.libraries, { ID: this.library_id });
      this.library_name = item.name;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.$emit('submit', { id: this.library_id, name: this.library_name });
    },
  },
};
</script>

<style scoped>

</style>
<style>
input[type="text"] {
  background: none !important;
}
.dialog_size{
  height: 300px;
}
</style>
