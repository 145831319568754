var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-bottom-navigation",
    { attrs: { color: "success", value: _vm.active } },
    [
      _c("v-btn", { attrs: { href: "/api/admin/wrong-schedulers" } }, [
        _vm._v("Wrong Schedulers")
      ]),
      _c("v-btn", { attrs: { href: "/api/admin/no-images" } }, [
        _vm._v("No images")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }