<template lang="pug">
  v-app
    v-container.white-board
      h3 Editing
      template(v-for="task in tasks")
        task(
          :task="task"
          :libraries="libraries"
          :edit="true"
          @take="take"
        )
</template>

<script>
import axios from 'axios';
import _ from 'lodash';

import Task from './Task';

export default {
  name: 'Edit',
  components: { Task },
  data() {
    return {
      tasks: {},
      libraries: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      const id = new URL(window.location.href).searchParams.get('id');
      axios.get(`/api/settings/edit-data?id=${id}`).then((response) => {
        this.tasks = _.get(response, 'data.schedulers', []);
        this.libraries = _.get(response, 'data.libraries', []);
      });
    },
    take() {
      window.location.href = '/api/dashboard';
    },
  },
};
</script>

<style scoped>

</style>
