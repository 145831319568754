<template lang="pug" class="v-application">
  v-row(justify="center")
    v-dialog(:value="visible" @click:outside="close"
      @keydown.esc="close" width="600")
      v-card
        v-toolbar(color="success")
          v-btn(icon dark @click="close")
            v-icon mdi-close
          v-toolbar-title Twitter Limitation
        v-card-text
          v-container.body-2
            h3 Image specifications and recommendations
            p Image files must meet all of the following criteria:
            ol
              li Supported image media types: JPG, PNG, GIF
              li Image size <= 5 MB, animated GIF size <= 15 MB
            h3 Animated GIF recommendations
            p A GIF may fail during Tweet creation even if it is within the file size limit.
              | Adhere to the following constraints to improve success rates.
              ol
                li Resolution should be <= 1280x1080 (width x height)
                li Number of frames <= 350
                li Number of pixels (width * height * num_frames) <= 300 million
                li File size <= 15Mb
            h3 Video specifications and recommendations
            p Recommended:
            ol
              li Video Codec: H264 High Profile
              li Frame Rates: 30 FPS, 60 FPS
              li Video Resolution: 1280x720 (landscape), 720x1280 (portrait), 720x720 (square)
              li Minimum Video Bitrate: 5,000 kbps
              li Minimum Audio Bitrate: 128 kbps
              li Audio Codec: AAC LC
              li Aspect Ratio: 16:9 (landscape or portrait), 1:1 (square)

</template>

<script>

export default {
  name: 'LimitationTwitter',

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
<style scoped>
ul{
  list-style: circle;
}
ol{
  list-style: circle;
}
</style>
