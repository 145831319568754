var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-container",
        { staticClass: "white-board" },
        [
          _c("h3", [_vm._v("Connect a Profile to Evergreen")]),
          _c("socials", {
            attrs: { limit: _vm.limit, ready: _vm.ready },
            on: { updateSettings: _vm.getData }
          })
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "white-board" },
        [
          _c("h3", [
            _vm._v("Setup your added: Facebook, Twitter, LinkedIn, Instagram")
          ]),
          _vm._l(_vm.tasks, function(task) {
            return [
              _c("task", {
                attrs: { task: task, libraries: _vm.libraries },
                on: {
                  take: function($event) {
                    return _vm.take($event)
                  }
                }
              })
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }