<template lang="pug">
  v-dialog(:value="visible" max-width="50%" @click:outside="close" @keydown.esc="close")
    v-card
      v-toolbar(color="success")
        v-btn(icon dark @click="close")
          v-icon mdi-close
        v-toolbar-title UnPause
      v-card-title.headline UnPause task?
      v-card-actions
        v-spacer
        v-btn(color="success" @click="unPause()" class="mx-2" text) YES, UnPause
        v-btn(color="error" @click="close" class="mx-2" text) NO, CANCEL
</template>

<script>

import axios from 'axios';

export default {
  name: 'UnPause',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    shid: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    unPause() {
      const data = { SHID: this.shid };
      axios.post('/api/setting/unpause', data);
      this.$emit('accept');
    },
  },
};
</script>

<style scoped>

</style>
